// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Password = require("../Util/Password");
var UserData = require("../Util/UserData");
var QueryString = require("../Util/QueryString");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");

function str(prim) {
  return prim;
}

function submit($$event) {
  $$event.preventDefault();
  return /* Http */Block.__(0, [/* Load */0]);
}

var component = ReasonReact.reducerComponent("ResetPassword");

function make(_children) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */(function (self) {
              var query = QueryString.parseQueryString(window.location.search);
              var match = Js_dict.get(query, "?token");
              if (match !== undefined) {
                var match$1 = match;
                if (match$1.tag) {
                  return Curry._1(self[/* send */3], /* GoToLogin */0);
                } else {
                  return Curry._1(self[/* send */3], /* ResetToken */Block.__(1, [match$1[0]]));
                }
              } else {
                return Curry._1(self[/* send */3], /* GoToLogin */0);
              }
            }),
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (self) {
              var match = self[/* state */1][/* fetch */0];
              var tmp;
              tmp = typeof match === "number" || match.tag ? React.createElement("div", undefined) : React.createElement("div", {
                      className: "login-error blink_me"
                    }, match[0]);
              return React.createElement("div", {
                          className: "login row",
                          name: "ResetPassword"
                        }, React.createElement("div", {
                              className: "col-lg-4 col-md-2 col-sm-1"
                            }), React.createElement("div", {
                              className: "login-content col-lg-4 col-md-8 col-sm-10"
                            }, React.createElement("form", {
                                  onSubmit: (function (e) {
                                      return Curry._1(self[/* send */3], (e.preventDefault(), /* Http */Block.__(0, [/* Load */0])));
                                    })
                                }, React.createElement("fieldset", undefined, "You are about to reset your KNL Portal password. ", React.createElement("strong", undefined, Password.passwordRequirement), React.createElement("br", undefined), React.createElement("br", undefined)), React.createElement("fieldset", undefined, React.createElement("legend", undefined, "Password"), React.createElement("input", {
                                          className: "login-input",
                                          name: "reset-password-1",
                                          type: "password",
                                          value: self[/* state */1][/* password */2],
                                          onChange: (function (e) {
                                              return Curry._1(self[/* send */3], /* PasswordUpdate */Block.__(2, [e.target.value]));
                                            })
                                        })), React.createElement("fieldset", undefined, React.createElement("legend", undefined, "Re-type password"), React.createElement("input", {
                                          className: "login-input",
                                          name: "reset-password-2",
                                          type: "password",
                                          value: self[/* state */1][/* passwordCheck */1],
                                          onChange: (function (e) {
                                              return Curry._1(self[/* send */3], /* PasswordCheckUpdate */Block.__(3, [e.target.value]));
                                            })
                                        })), tmp, React.createElement("button", {
                                      className: "log-in-button primary",
                                      disabled: !Password.validNewPassword(self[/* state */1][/* password */2], self[/* state */1][/* passwordCheck */1]),
                                      name: "submit"
                                    }, "Submit"))));
            }),
          /* initialState */(function (param) {
              return /* record */[
                      /* fetch : Idle */0,
                      /* passwordCheck */"",
                      /* password */"",
                      /* token */undefined
                    ];
            }),
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */(function (action, state) {
              if (typeof action === "number") {
                return /* SideEffects */Block.__(1, [(function (_self) {
                              return ReasonReactRouter.push("/login");
                            })]);
              } else {
                switch (action.tag | 0) {
                  case /* Http */0 :
                      var match = action[0];
                      if (typeof match === "number") {
                        return /* UpdateWithSideEffects */Block.__(2, [
                                  /* record */[
                                    /* fetch : Loading */1,
                                    /* passwordCheck */state[/* passwordCheck */1],
                                    /* password */state[/* password */2],
                                    /* token */state[/* token */3]
                                  ],
                                  (function (self) {
                                      var match = state[/* token */3];
                                      if (match !== undefined) {
                                        UserData.putPasswordUser(match, state[/* password */2]).then((function (result) {
                                                return Promise.resolve(Curry._1(self[/* send */3], /* Http */Block.__(0, [result])));
                                              }));
                                        return /* () */0;
                                      } else {
                                        Curry._1(self[/* send */3], /* GoToLogin */0);
                                        return /* () */0;
                                      }
                                    })
                                ]);
                      } else if (match.tag) {
                        var match$1 = match[0];
                        if (match$1) {
                          return /* Update */Block.__(0, [/* record */[
                                      /* fetch : Failed */Block.__(0, [match$1[0]]),
                                      /* passwordCheck */state[/* passwordCheck */1],
                                      /* password */state[/* password */2],
                                      /* token */state[/* token */3]
                                    ]]);
                        } else {
                          return /* Update */Block.__(0, [/* record */[
                                      /* fetch : Failed */Block.__(0, ["Unauthorized"]),
                                      /* passwordCheck */state[/* passwordCheck */1],
                                      /* password */state[/* password */2],
                                      /* token */state[/* token */3]
                                    ]]);
                        }
                      } else {
                        return /* UpdateWithSideEffects */Block.__(2, [
                                  /* record */[
                                    /* fetch : Done */Block.__(1, [match[0]]),
                                    /* passwordCheck */state[/* passwordCheck */1],
                                    /* password */state[/* password */2],
                                    /* token */state[/* token */3]
                                  ],
                                  (function (self) {
                                      return Curry._1(self[/* send */3], /* GoToLogin */0);
                                    })
                                ]);
                      }
                  case /* ResetToken */1 :
                      return /* Update */Block.__(0, [/* record */[
                                  /* fetch */state[/* fetch */0],
                                  /* passwordCheck */state[/* passwordCheck */1],
                                  /* password */state[/* password */2],
                                  /* token */action[0]
                                ]]);
                  case /* PasswordUpdate */2 :
                      return /* Update */Block.__(0, [/* record */[
                                  /* fetch */state[/* fetch */0],
                                  /* passwordCheck */state[/* passwordCheck */1],
                                  /* password */action[0],
                                  /* token */state[/* token */3]
                                ]]);
                  case /* PasswordCheckUpdate */3 :
                      return /* Update */Block.__(0, [/* record */[
                                  /* fetch */state[/* fetch */0],
                                  /* passwordCheck */action[0],
                                  /* password */state[/* password */2],
                                  /* token */state[/* token */3]
                                ]]);
                  
                }
              }
            }),
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.str = str;
exports.submit = submit;
exports.component = component;
exports.make = make;
/* component Not a pure module */
