import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export default class MapLegend extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false
        }
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    handleClickOutside = event => {
        const domNode = ReactDOM.findDOMNode(this);
        if (!domNode || !domNode.contains(event.target)) {
            this.setState({
                visible: false
            });
        }
    }

    render() {
        return (
            <div className="map-legend" {...this.props}>
              <div 
                  onClick={(e) => {this.setState({visible: !this.state.visible})}}
                  className="map-legend-header">
                  Legend {(this.state.visible && <i style={{float: 'right'}} className="fa fa-chevron-up"/>) || <i style={{float: 'right'}} className="fa fa-chevron-down"/>}
              </div>
              {this.state.visible && <div className="map-legend-body">
                <div className="item white-square"><span>Vessel not moving</span></div>
                <div className="item white-arrow"><span>Vessel in motion</span></div>
                <div className="item yellow-arrow"><span>Vessel speed 20kn or more</span></div>
                <div className="item red-arrow"><span>Vessel speed 2kn or less</span></div>
                <div className="item orange-arrow"><span>Speed values from 2kn to 20kn <br/> are scaled from blue to green to yellow</span></div>
              </div>}
            </div>
        );
    }
}
