import * as L from "leaflet";
import { MapControl, withLeaflet } from "react-leaflet";
import "./L.CanvasLayer";
import scaleSpeed from "../../Util/scaleSpeed";

const ITEMS_PER_DATUM = 8;
const POINTS_TO_DRAW = 200;

const VERTEX_SHADER = `
precision highp float;

uniform mat4 u_matrix;
uniform float u_scale;
uniform float u_zoom;

uniform int u_drawObjects[${POINTS_TO_DRAW}];

attribute vec4 a_vertex;
attribute float a_speed;
attribute float a_angle;
attribute float a_index;
attribute float a_highlight;
attribute float a_nocolor;
attribute float a_objectOffset;

varying float vZoom;
varying vec3 vBC;
varying float vSpeed;
varying float vHighlight;
varying float vNocolor;

void main() {
    highp int objectOffset = int(a_objectOffset);
    vNocolor = a_nocolor;
    vZoom = u_zoom;
    vSpeed = a_speed;
    vHighlight = a_highlight;
    float scale = u_scale;
    vec4 vertex = a_vertex;
    float rad_angle = a_angle * 3.141592653589793 / 180.0;

    if (vHighlight > 0.0) {
        scale *= 1.8;
    }

    // Arrow dimensions
    float arrow_height = 0.03 * 1.8;
    float arrow_width = 0.02 * 1.8;
    
    // Calculate the origin to wich rotate from the index value
    float origX = vertex[0];
    float origY = vertex[1];

    float offsetX = 0.0;
    float offsetY = 0.0;

    if (a_speed > 0.1) {
        if (mod(a_index, 6.0) < 1.0) {
            offsetX = 1.0;
            offsetY = -1.0;
        } else if (mod(a_index, 6.0) < 2.0) {
            offsetX = 0.0;
            offsetY = 1.0;
        } else if (mod(a_index, 6.0) < 3.0) {
            offsetX = 0.0;
            offsetY = -1.0;
        } else if (mod(a_index, 6.0) < 4.0) {
            offsetX = 0.0;
            offsetY = -1.0;
        } else if (mod(a_index, 6.0) < 5.0) {
            offsetX = 0.0;
            offsetY = 1.0;
        } else {
            offsetX = -1.0;
            offsetY = -1.0;
        }
    } else {
        rad_angle = 0.0;
        arrow_height = arrow_width;
        if (mod(a_index, 6.0) < 1.0) {
            offsetX = 1.0;
            offsetY = 0.0;
        } else if (mod(a_index, 6.0) < 2.0) {
            offsetX = 0.0;
            offsetY = 1.0;
        } else if (mod(a_index, 6.0) < 3.0) {
            offsetX = 0.0;
            offsetY = -1.0;
        } else if (mod(a_index, 6.0) < 4.0) {
            offsetX = 0.0;
            offsetY = -1.0;
        } else if (mod(a_index, 6.0) < 5.0) {
            offsetX = 0.0;
            offsetY = 1.0;
        } else {
            offsetX = -1.0;
            offsetY = 0.0;

        }
    }

    if (mod(a_index, 6.0) < 1.0) {
        vBC = vec3(0, 0, 1);
    } else if (mod(a_index, 6.0) < 2.0) {
        vBC = vec3(1, 0, 0);
    } else if (mod(a_index, 6.0) < 3.0) {
        vBC = vec3(0, 1, 1);
    } else if (mod(a_index, 6.0) < 4.0) {
        vBC = vec3(0, 1, 1);
    } else if (mod(a_index, 6.0) < 5.0) {
        vBC = vec3(1, 0, 0);
    } else {
        vBC = vec3(0, 1, 0);
    }
    
    vertex[0] = vertex[0] + (arrow_width * offsetX * scale);
    vertex[1] = vertex[1] + (arrow_height * offsetY * scale);

    // Rotate the points
    float newX = origX + (vertex[0] - origX) * cos(rad_angle) + (vertex[1] - origY) * sin(rad_angle);
    float newY = origY + (vertex[0] - origX) * sin(rad_angle) - (vertex[1] - origY) * cos(rad_angle);

    vec4 result = vec4(newX, newY, 1, 1);
    // Multiply each vertex by a matrix and make it as the position
    vec4 temp = u_matrix * result;


    int draw = 0;

    for (int i = 0; i < ${POINTS_TO_DRAW}; ++i) {
        
        if (u_drawObjects[i] == -1) {
            break;
        }

        if (u_drawObjects[i] == objectOffset) {
            draw = 1;
            break;
        }
    
    }

    if (draw == 1) {
        gl_Position = vec4(temp[0], temp[1], temp[2], 1.0);
    } else {
        gl_Position = vec4(0, 0, 0, -1.0);
    }

}`;

const FRAGMENT_SHADER = `
#extension GL_OES_standard_derivatives : enable
precision lowp float;

varying vec3 vBC;
varying float vZoom;
varying float vSpeed;
varying float vHighlight;
varying float vNocolor;

float edgeFactor(){
    vec3 d = fwidth(vBC);
    vec3 a3 = smoothstep(vec3(0.0), d * 1.5, vBC);
    return min(min(a3.x, a3.y), a3.z);
}

void main() {
    float alpha = (1.0 - (1.0 - edgeFactor()) * 0.8);
    if (vHighlight < 1.0) {
        alpha += vZoom;
    }
    if (vNocolor > 0.0) {
        gl_FragColor = vec4(0.5 * alpha, 0.5 * alpha, 0.5 * alpha, 1);
    } else {
        gl_FragColor = vec4(1.0 * alpha, vSpeed * alpha, 0, 1);
    }
}`;


function distance(lat1, lon1, lat2, lon2) {
	if ((lat1 === lat2) && (lon1 === lon2)) {
		return 0;
	}
	else {
		const radlat1 = Math.PI * lat1 / 180;
		const radlat2 = Math.PI * lat2/180;
		const theta = lon1 - lon2;
		const radtheta = Math.PI * theta/180;
		let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = dist * 180 / Math.PI;
        dist = dist * 60 * 1.1515;
        return dist;
	}
}

function timeDiff(a, b) {
    const timeA = a.time.replace(" ", "T");
    const timeB = b.time.replace(" ", "T");
    const diff = Math.abs(new Date(timeA).valueOf() - new Date(timeB).valueOf()) / 1000;
    return diff;
}


export const distancePerZoomLevel = (zoom) => {
    switch (parseInt(zoom)) {
        case 15: return 0.15;
        case 14: return 0.25;
        case 13: return 0.5;
        case 12: return 1;
        case 11: return 2;
        case 10: return 3;
        case 9: return 5;
        case 8: return 10;
        case 7: return 15;
        case 6: return 25;
        case 5: return 50;
        case 4: return 100;
        case 3: return 175;
        case 2: return 250;
        default: return 0.01;
    }
};

export const shouldDraw = (prevPoint, point, distanceThreshold) => {
    if (prevPoint === undefined) {
        return true;
    } else if (point.highlighted) {
        return true;
    } else if (distance(prevPoint.latitude, prevPoint.longitude, point.latitude, point.longitude) > distanceThreshold) { 
        return true;
    }
    return false;
};

// Projects latitude and longitude to pixel coordinates
const latLngToPixelXY = (latitude, longitude) => {
    var pi_180 = Math.PI / 180.0;
    var pi_4 = Math.PI * 4;
    var sinLatitude = Math.sin(latitude * pi_180);
    var pixelY = (0.5 - Math.log((1 + sinLatitude) / (1 - sinLatitude)) / (pi_4)) * 256;
    var pixelX = ((longitude + 180) / 360) * 256;
    var pixel = { x: pixelX, y: pixelY };
    return pixel;
};

const translateMatrix = (matrix, tx, ty) => {
    matrix[12] += matrix[0] * tx + matrix[4] * ty;
    matrix[13] += matrix[1] * tx + matrix[5] * ty;
    matrix[14] += matrix[2] * tx + matrix[6] * ty;
    matrix[15] += matrix[3] * tx + matrix[7] * ty;
};

const scaleMatrix = (matrix, scaleX, scaleY) => {
    matrix[0] *= scaleX;
    matrix[1] *= scaleX;
    matrix[2] *= scaleX;
    matrix[3] *= scaleX;

    matrix[4] *= scaleY;
    matrix[5] *= scaleY;
    matrix[6] *= scaleY;
    matrix[7] *= scaleY;
};

const getGl = (canvas) => {
    for(const name of ['webgl', 'experimental-webgl']) {
        const gl = canvas.getContext(name);
        if(gl) return gl;
    }
    return undefined;
};


const init = (gl) => {        

		const pixelsToWebGLMatrix = new Float32Array(16);
		const mapMatrix = new Float32Array(16);
        
        gl.getExtension('OES_standard_derivatives');
        // WebGl setup
		const vertexShader = gl.createShader(gl.VERTEX_SHADER);
		gl.shaderSource(vertexShader, VERTEX_SHADER);
        gl.compileShader(vertexShader);
        const vertexShaderLog = gl.getShaderInfoLog(vertexShader); 
        if (vertexShaderLog.length > 0) {
            console.error(vertexShaderLog);
        }
        
		const fragmentShader = gl.createShader(gl.FRAGMENT_SHADER);
		gl.shaderSource(fragmentShader, FRAGMENT_SHADER);
        gl.compileShader(fragmentShader);
        const fragmentShaderLog = gl.getShaderInfoLog(fragmentShader); 
        if (fragmentShaderLog.length > 0) {
            console.error(fragmentShaderLog);
        }
        
        // Link shaders to create our program
		const program = gl.createProgram();
		gl.attachShader(program, vertexShader);
		gl.attachShader(program, fragmentShader);
		gl.linkProgram(program);
		gl.useProgram(program);
       
        gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
		gl.enable(gl.BLEND);
       
        // Look up the locations for the inputs to shaders.
        const uniforms = {
            u_matLoc: gl.getUniformLocation(program, "u_matrix"),
            u_scaleLoc: gl.getUniformLocation(program, "u_scale"),
            u_zoomLoc: gl.getUniformLocation(program, "u_zoom"),
            u_drawObjectsLoc: gl.getUniformLocation(program, "u_drawObjects"),
        };

        const attributes = {
            a_vertexLoc: gl.getAttribLocation(program, "a_vertex"),
            a_speedLoc: gl.getAttribLocation(program, "a_speed"),
            a_angleLoc: gl.getAttribLocation(program, "a_angle"),
            a_indexLoc: gl.getAttribLocation(program, "a_index"),
            a_highlightLoc: gl.getAttribLocation(program, "a_highlight"),
            a_objectOffsetLoc: gl.getAttribLocation(program, "a_objectOffset"),
            a_nocolorLoc: gl.getAttribLocation(program, "a_nocolor"),
        };

        return [pixelsToWebGLMatrix, mapMatrix, uniforms, attributes];
};

// Adds data to buffer for drawing
const addData = (gl, map, vertBuffer, locationData, { a_vertexLoc, a_speedLoc, a_angleLoc, a_indexLoc, a_highlightLoc, a_nocolorLoc, a_objectOffsetLoc }) => {
    const glData = [];
    for (let objectOffset = locationData.length - 1; objectOffset >= 0; objectOffset--) {
        const rtposData = locationData[objectOffset];
        const pixel = latLngToPixelXY(rtposData.latitude, rtposData.longitude);
        if (rtposData.speed === undefined || rtposData.speed === null) {
            rtposData.speed = 0;
        }
        if (rtposData.heading === undefined || rtposData.heading === null) {
            rtposData.heading = 0;
        }
        const scaledSpeed = scaleSpeed(rtposData.speed);
        for (let vertexi = 0; vertexi < 6; vertexi++) { 
            glData.push(
                pixel.x,
                pixel.y,
                scaledSpeed,
                rtposData.highlighted ? rtposData.bearing : rtposData.heading,
                glData.length / ITEMS_PER_DATUM,
                rtposData.highlighted ? 1.0 : 0.0,
                rtposData.nocolor ? 1.0 : 0.0,
                objectOffset,
            );
        }
    }
    const numPoints = glData.length / ITEMS_PER_DATUM;

    
    const vertArray = new Float32Array(glData);

    gl.bindBuffer(gl.ARRAY_BUFFER, vertBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, vertArray, gl.STATIC_DRAW);
    
    const fsize = vertArray.BYTES_PER_ELEMENT;
    
    // Vertex buffer
    gl.vertexAttribPointer(a_vertexLoc, 2, gl.FLOAT, false, fsize * ITEMS_PER_DATUM, 0);
    gl.enableVertexAttribArray(a_vertexLoc);

    // Speed buffer
    gl.vertexAttribPointer(a_speedLoc, 1, gl.FLOAT, false, fsize * ITEMS_PER_DATUM, fsize * 2);
    gl.enableVertexAttribArray(a_speedLoc);
    
    // Angle buffer
    gl.vertexAttribPointer(a_angleLoc, 1, gl.FLOAT, false, fsize * ITEMS_PER_DATUM, fsize * 3);
    gl.enableVertexAttribArray(a_angleLoc);

    // Index buffer
    gl.vertexAttribPointer(a_indexLoc, 1, gl.FLOAT, false, fsize * ITEMS_PER_DATUM, fsize * 4);
    gl.enableVertexAttribArray(a_indexLoc);

    // Higlight buffer
    gl.vertexAttribPointer(a_highlightLoc, 1, gl.FLOAT, false, fsize * ITEMS_PER_DATUM, fsize * 5);
    gl.enableVertexAttribArray(a_highlightLoc);

    // No color buffer
    gl.vertexAttribPointer(a_nocolorLoc, 1, gl.FLOAT, false, fsize * ITEMS_PER_DATUM, fsize * 6);
    gl.enableVertexAttribArray(a_nocolorLoc);

    // Object offset location
    gl.vertexAttribPointer(a_objectOffsetLoc, 1, gl.FLOAT, false, fsize * ITEMS_PER_DATUM, fsize * 7);
    gl.enableVertexAttribArray(a_objectOffsetLoc);

    return numPoints;
};


const draw = (gl, points, map,  pixelsToWebGLMatrix, mapMatrix, numPoints, { u_zoomLoc, u_matLoc, u_scaleLoc, u_drawObjectsLoc }) => {
    gl.clear(gl.COLOR_BUFFER_BIT);
    pixelsToWebGLMatrix.set([2 / gl.canvas.width, 0, 0, 0, 0, -2 / gl.canvas.height, 0, 0, 0, 0, 0, 0, -1, 1, 0, 1]);
    mapMatrix.set(pixelsToWebGLMatrix);
    const bounds = map.getBounds();
    const topLeft = new L.LatLng(bounds.getNorth(), bounds.getWest());
    const offset = latLngToPixelXY(topLeft.lat, topLeft.lng);
    
    // Scale to current zoom
    const zoom = map.getZoom();
    const scale = Math.pow(2, zoom);
    scaleMatrix(mapMatrix, scale, scale);
    translateMatrix(mapMatrix, -offset.x, -offset.y);
    const zoomValue = (function() {
        switch (parseInt(zoom)) {
            case 18: return 0.003;
            case 17: return 0.005;
            case 16: return 0.01;
            case 15: return 0.02;
            case 14: return 0.03;
            case 13: return 0.05;
            case 12: return 0.1;
            case 11: return 0.2;
            case 10: return 0.3;
            case 9: return 0.5;
            case 8: return 0.9;
            case 7: return 1.5;
            case 6: return 3.0;
            case 5: return 6.0;
            case 4: return 12.0;
            case 3: return 16.0;
            case 2: return 30.0;
            default: return 0.5;
        }
    })();

    let scaledZoom = 1.0 - ((zoom - 2) / (9 - 2));
    if (scaledZoom < 0.0) {
        scaledZoom = 0.0;
    }

    const drawObjects = [];
    const distanceThreshold = distancePerZoomLevel(zoom);

    let prevPoint = undefined;
    const mapBounds = map.getBounds();
    for (const [index, point] of points.entries()) {
        if (shouldDraw(prevPoint, point, distanceThreshold)) {
            prevPoint = point;
            if (drawObjects.length < (POINTS_TO_DRAW - 2) && mapBounds.contains([point.latitude, point.longitude])){
                drawObjects.push(index);
            }
        }
    }

    drawObjects.push(points.length - 1);
    drawObjects.push(-1);

    // Attachment of uniforms 
    gl.uniformMatrix4fv(u_matLoc, false, mapMatrix);
    gl.uniform1f(u_scaleLoc, zoomValue);
    gl.uniform1f(u_zoomLoc, scaledZoom);
    gl.uniform1iv(u_drawObjectsLoc, drawObjects);

    // Triangle draw
    gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);
    gl.drawArrays(gl.TRIANGLES, 0, numPoints);
};

class GLArrows extends MapControl {


    constructor(props) {
        super(props);
    }

    updateLeafletElement(fromProps, toProps) {
        const { points } = toProps;
        if (points.length === 0 || !this.drawData || !this.addData) {
            return null;
        }
        this.addData(points);
        this.drawData(points.length * 6);
    }

    createLeafletElement() {
        const that = this;
        const myCustomCanvasDraw = function() {
            
            this.onLayerDidMount = function() {
                this._map.on("zoomend", this.zoomend);
                this._map.on("zoomstart", this.zoomstart);
                this._map.on("moveend", this.moveend);
                this._map.on("resize", this.resize);
                this.drawTimeout = undefined;
                const map = this._map;
                const canvas = this._canvas;
                this.gl = getGl(canvas);
                if (this.gl === undefined) {
                    console.error("No WebGL available!");
                    return;
                }
                const [pixelsToWebGLMatrix, mapMatrix, uniforms, attributes] = init(this.gl);
                this.pixelsToWebGLMatrix = pixelsToWebGLMatrix;
                this.mapMatrix = mapMatrix;
                this.uniforms = uniforms;
                this.attributes = attributes;
                that.addData = (nPoints) => {
                    if (this.vertBuffer) {
                        this.gl.deleteBuffer(this.vertBuffer);
                    }
                    this.vertBuffer = this.gl.createBuffer();
                    addData(this.gl, map, this.vertBuffer, nPoints, this.attributes)
                };
                that.drawData = (nPointCount) => draw(this.gl, that.props.points, map, this.pixelsToWebGLMatrix, this.mapMatrix, nPointCount, this.uniforms);
            };

            this.zoomstart = function() {
                if (this.gl) {
                    this.gl.clear(this.gl.COLOR_BUFFER_BIT);
                }
            };

            this.resize = function () {
                const { points } = that.props;
                that.drawData(points.length * 6);
            };
            
            this.zoomend = function() {
                const { points } = that.props;
                that.drawData(points.length * 6);
            };
            
            this.moveend = function() {
                const { points } = that.props;
                that.drawData(points.length * 6);
            };

            this.onLayerWillUnmount  = function() {

            };
            
        };
        
        myCustomCanvasDraw.prototype = new L.CanvasLayer();         
        return new myCustomCanvasDraw();
    }
}

export default withLeaflet(GLArrows);