// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var KNLCard = require("../Components/KNLCard");
var InviteForm = require("../Components/InviteForm");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var CustomerList = require("../Components/CustomerList");
var VesselsAndRadiosForm = require("../Components/VesselsAndRadiosForm");

var component = ReasonReact.statelessComponent("CustomerManagement");

function make(store, _children) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (self) {
              return React.createElement("div", {
                          className: "row"
                        }, React.createElement("div", {
                              className: "col-lg-4 col-md-3 col-sm-12"
                            }, ReasonReact.element(undefined, undefined, KNLCard.make(undefined, "CUSTOMERS", /* array */[ReasonReact.element(undefined, undefined, CustomerList.make(store, /* array */[]))]))), React.createElement("div", {
                              className: "col-lg-4 col-md-3 col-sm-12"
                            }, ReasonReact.element(undefined, undefined, KNLCard.make(undefined, "ADD NEW VESSELS AND RADIOS", /* array */[ReasonReact.element(undefined, undefined, VesselsAndRadiosForm.make(store, /* array */[]))]))), React.createElement("div", {
                              className: "col-lg-4 col-md-3 col-sm-12"
                            }, ReasonReact.element(undefined, undefined, KNLCard.make(undefined, "INVITE A NEW CUSTOMER", /* array */[ReasonReact.element(undefined, undefined, InviteForm.make(store, /* array */[]))]))));
            }),
          /* initialState */component[/* initialState */10],
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */component[/* reducer */12],
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.component = component;
exports.make = make;
/* component Not a pure module */
