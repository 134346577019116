// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Loading = require("./Loading");
var UserData = require("../Util/UserData");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var CustomerListItem = require("./CustomerListItem");

function str(prim) {
  return prim;
}

var component = ReasonReact.reducerComponent("CustomerList");

function make(store, _children) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */(function (self) {
              return Curry._1(self[/* send */3], /* Customers */[/* Load */0]);
            }),
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (self) {
              var match = self[/* state */1][/* customers */0];
              var tmp;
              tmp = typeof match === "number" || match.tag !== /* Done */1 ? React.createElement("li", undefined) : $$Array.of_list(List.map((function (x) {
                            return ReasonReact.element(x[/* uuid */2], undefined, CustomerListItem.make((function (param) {
                                              return Curry._1(self[/* send */3], /* Customers */[/* Load */0]);
                                            }), store, x, /* array */[]));
                          }), match[0]));
              return React.createElement("div", {
                          className: "row customer-list"
                        }, self[/* state */1][/* customers */0] === /* Loading */1 ? ReasonReact.element(undefined, undefined, Loading.make(/* array */[])) : React.createElement("div", undefined), React.createElement("ul", undefined, tmp));
            }),
          /* initialState */(function (param) {
              return /* record */[/* customers : Idle */0];
            }),
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */(function (action, state) {
              var match = action[0];
              if (typeof match === "number") {
                return /* UpdateWithSideEffects */Block.__(2, [
                          /* record */[/* customers : Loading */1],
                          (function (self) {
                              UserData.getCustomersVessels(/* () */0).then((function (result) {
                                      return Promise.resolve(Curry._1(self[/* send */3], /* Customers */[result]));
                                    }));
                              return /* () */0;
                            })
                        ]);
              } else if (match.tag) {
                var match$1 = match[0];
                if (match$1) {
                  return /* Update */Block.__(0, [/* record */[/* customers : Failed */Block.__(0, [match$1[0]])]]);
                } else {
                  return /* UpdateWithSideEffects */Block.__(2, [
                            /* record */[/* customers : Failed */Block.__(0, ["Unauthorized"])],
                            (function (_self) {
                                return Curry._1(store[/* logout */2], /* () */0);
                              })
                          ]);
                }
              } else {
                return /* Update */Block.__(0, [/* record */[/* customers : Done */Block.__(1, [match[0]])]]);
              }
            }),
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.str = str;
exports.component = component;
exports.make = make;
/* component Not a pure module */
