// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");

function makeSafe(s) {
  return decodeURI(s.replace((/\+/g), " ")).replace((/&/g), "&amp;").replace((/</g), "&lt;").replace((/>/g), "&gt;");
}

function addKeyValue(accumulator, kvPair) {
  var match = kvPair.split("=");
  if (match.length !== 2) {
    return accumulator;
  } else {
    var key = match[0];
    if (key === "") {
      var match$1 = match[1];
      if (match$1 === "") {
        return accumulator;
      }
      
    }
    var codedValue = match[1];
    var value = makeSafe(codedValue);
    var match$2 = Js_dict.get(accumulator, key);
    if (match$2 !== undefined) {
      var v = match$2;
      if (v.tag) {
        accumulator[key] = /* Multiple */Block.__(1, [/* :: */[
              value,
              v[0]
            ]]);
      } else {
        accumulator[key] = /* Multiple */Block.__(1, [/* :: */[
              value,
              /* :: */[
                v[0],
                /* [] */0
              ]
            ]]);
      }
    } else {
      accumulator[key] = /* Single */Block.__(0, [value]);
    }
    return accumulator;
  }
}

function parseQueryString(qString) {
  var result = Js_dict.fromList(/* [] */0);
  var kvPairs = qString.split("&");
  return $$Array.fold_left(addKeyValue, result, kvPairs);
}

function showItem(query, key) {
  var item = Js_dict.get(query, key);
  if (item !== undefined) {
    console.log(item[0]);
    return /* () */0;
  } else {
    console.log("no key");
    return /* () */0;
  }
}

exports.makeSafe = makeSafe;
exports.addKeyValue = addKeyValue;
exports.parseQueryString = parseQueryString;
exports.showItem = showItem;
/* No side effect */
