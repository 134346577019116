// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReactDom = require("react-dom");
var ReactDOMRe = require("reason-react/src/ReactDOMRe.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");

function str(prim) {
  return prim;
}

function mkStyle(width, height, top, left) {
  return {
          backgroundColor: "#414d61",
          border: "2px solid gray",
          color: "white",
          height: String(height) + "px",
          left: left,
          position: "absolute",
          top: top,
          width: String(width) + "px",
          zIndex: "99999"
        };
}

function setDialogRef(theRef, param) {
  param[/* state */1][/* dialogRef */0][0] = (theRef == null) ? undefined : Caml_option.some(theRef);
  return /* () */0;
}

var component = ReasonReact.reducerComponent("Dialog");

function getTop(height) {
  return String(window.pageYOffset + ((window.innerHeight / 2 | 0) - (height / 2 | 0) | 0) | 0) + "px";
}

function getLeft(width) {
  return String((window.innerWidth / 2 | 0) - (width / 2 | 0) | 0) + "px";
}

function onResize(_e, self) {
  return Curry._1(self[/* send */3], /* Resize */0);
}

function make(width, height, onOk, onCancel, children) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */(function (self) {
              var resizeHandler = Curry._1(self[/* handle */0], onResize);
              window.addEventListener("scroll", resizeHandler);
              window.addEventListener("resize", resizeHandler);
              Curry._1(self[/* onUnmount */4], (function (param) {
                      window.removeEventListener("scroll", resizeHandler);
                      window.removeEventListener("resize", resizeHandler);
                      return /* () */0;
                    }));
              var match = self[/* state */1][/* dialogRef */0][0];
              if (match !== undefined) {
                return Caml_option.valFromOption(match).focus();
              } else {
                console.log("?");
                return /* () */0;
              }
            }),
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (self) {
              var html = React.createElement("div", {
                    ref: Curry._1(self[/* handle */0], setDialogRef),
                    className: "dialog-re",
                    style: mkStyle(width, height, self[/* state */1][/* top */1], self[/* state */1][/* left */2]),
                    tabIndex: 0,
                    onKeyPress: (function (e) {
                        if (e.key === "Enter") {
                          Curry._1(onOk, /* () */0);
                          return /* () */0;
                        } else {
                          return /* () */0;
                        }
                      })
                  }, ReactDOMRe.createElementVariadic("div", undefined, children), React.createElement("div", {
                        style: {
                          textAlign: "center"
                        }
                      }, React.createElement("button", {
                            className: "primary",
                            name: "cancel",
                            onClick: (function (param) {
                                return Curry._1(onCancel, /* () */0);
                              })
                          }, "CANCEL"), React.createElement("button", {
                            className: "primary",
                            name: "ok",
                            onClick: (function (param) {
                                return Curry._1(onOk, /* () */0);
                              })
                          }, "OK")));
              var match = document.getElementById("root");
              if (match == null) {
                return html;
              } else {
                return ReactDom.createPortal(html, match);
              }
            }),
          /* initialState */(function (param) {
              return /* record */[
                      /* dialogRef : record */[/* contents */undefined],
                      /* top */getTop(height),
                      /* left */getLeft(width)
                    ];
            }),
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */(function (action, state) {
              return /* Update */Block.__(0, [/* record */[
                          /* dialogRef */state[/* dialogRef */0],
                          /* top */getTop(height),
                          /* left */getLeft(width)
                        ]]);
            }),
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.str = str;
exports.mkStyle = mkStyle;
exports.setDialogRef = setDialogRef;
exports.component = component;
exports.getTop = getTop;
exports.getLeft = getLeft;
exports.onResize = onResize;
exports.make = make;
/* component Not a pure module */
