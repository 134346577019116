// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var App = require("./app");
var ReactDOMRe = require("reason-react/src/ReactDOMRe.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var ServiceWorker = require("./serviceWorker");

((require('./app.scss')));

((require('../node_modules/font-awesome/css/font-awesome.css')));

((require('../node_modules/font-awesome/fonts/FontAwesome.otf')));

((require('../node_modules/font-awesome/fonts/fontawesome-webfont.svg')));

((require('../node_modules/font-awesome/fonts/fontawesome-webfont.eot')));

((require('../node_modules/font-awesome/fonts/fontawesome-webfont.ttf')));

((require('../node_modules/font-awesome/fonts/fontawesome-webfont.woff')));

((require('../node_modules/font-awesome/fonts/fontawesome-webfont.woff2')));

function register_service_worker(prim) {
  ServiceWorker.register();
  return /* () */0;
}

function unregister_service_worker(prim) {
  ServiceWorker.unregister();
  return /* () */0;
}

ReactDOMRe.renderToElementWithId(ReasonReact.element(undefined, undefined, App.make(/* array */[])), "root");

ServiceWorker.unregister();

exports.register_service_worker = register_service_worker;
exports.unregister_service_worker = unregister_service_worker;
/*  Not a pure module */
