import L from 'leaflet';
import scaleSpeed from '../Util/scaleSpeed';

const squareDimensions = [18, 18];
const arrowDimensions = [16, 24];

const greenArrowSvg = require('../mapmarker/green-arrow.svg');
const greenSquareSvg = require('../mapmarker/green-square.svg');
const yellowArrowSvg = require('../mapmarker/yellow-arrow.svg');
const yellowSquareSvg = require('../mapmarker/yellow-square.svg');
const redArrowSvg = require('../mapmarker/red-arrow.svg');
const redSquareSvg = require('../mapmarker/red-square.svg');
const whiteArrowSvg = require('../mapmarker/white-arrow.svg');
const whiteSquareSvg = require('../mapmarker/white-square.svg');

const rotateGen = (deg) => {
    return `transform: rotate(${deg}deg); \
    -moz-transform: rotate(${deg}deg); \
    -webkit-transform: rotate(${deg}deg); \
    -o-transform: rotate(${deg}deg); \
    -ms-transform: rotate(${deg}deg);`;
};

const ROT45 = rotateGen(45);

const ARROW_WIDTH = 14;
const ARROW_HEIGHT = 21;

const ARROW_A = [(ARROW_WIDTH / 2), 0];
const ARROW_B = [0, ARROW_HEIGHT];
const ARROW_C = [ARROW_WIDTH, ARROW_HEIGHT];

const SQUARE_SIZE = 14;

/*
const speedToColor = (speed) => {
    const R = Math.floor((speed * (154 - 50)) + 50);
    const G = Math.floor(((speed * speed) * (205 - 167)) + 167)
    const B = Math.floor(((1.0 - (speed * speed)) * (184 - 64)) + 64);
    return `rgba(${R}, ${G}, ${B})`;
    const R = Math.floor((2.0 * (1.0 - speed)) * 255);
    const G = Math.floor(((2.0 * (speed)) * 205) + 50);
    const B = 0;
    return `rgba(${R}, ${G}, ${B})`;
}
*/


const speedToColor = speed => {
    if (speed < 1.9) { 
        return "rgba(70,165,202)";
    }
    else if (speed < 3.9) { 
        return "rgba(77,169,176)";
    }
    else if (speed < 5.9) { 
        return "rgba(82,172,148)";
    }
    else if (speed < 7.9) { 
        return "rgba(87,177,127)";
    }
    else if (speed < 9.9) { 
        return "rgba(92,181,90)";
    }
    else if (speed < 11.9) { 
        return "rgba(110,191,85)";
    }
    else if (speed < 13.9) { 
        return "rgba(135,196,86)";
    }
    else if (speed < 15.9) { 
        return "rgba(166,206,67)";
    }
    else if (speed < 17.9) { 
        return "rgba(198,217,46)";
    }
    else if (speed < 19.9) { 
        return "rgba(211,222,38)";
    }
    else { 
        return "rgba(226,232,89)";
    }
}

const GREYED_COLOR = "rgba(147,134,96)";

const svgSource = (oSpeed, degree, scale, greyed) => {
    const speed = scaleSpeed(oSpeed);
    if (speed < 0.1) {
        let color = "";
        if (greyed === true) {
            // color = "rgba(128,128,128)";
            color = GREYED_COLOR;
        } else {
            color = speedToColor(oSpeed * 1.94384449); // "rgba(255, 25, 0)";
        }
        return new L.divIcon({
            iconSize: [SQUARE_SIZE, SQUARE_SIZE],
            iconAnchor: [(SQUARE_SIZE / 2), Math.floor(SQUARE_SIZE * 0.8)],
            className: '',
            html: (
                `<svg
                    xmlns="http://www.w3.org/2000/svg" 
                    xmlns:xlink= "http://www.w3.org/1999/xlink" 
                    width="${SQUARE_SIZE}" 
                    height="${SQUARE_SIZE}" 
                    style="${ROT45} padding: 0; margin: 0; overflow: visible;">    
                    <rect 
                        transform="scale(${scale ? 1.2 : 1.0})"
                        width="${SQUARE_SIZE}"
                        height="${SQUARE_SIZE}"
                        style="fill:${color};stroke:#000000;" />
                </svg>`
            )
        });
    } else {
        let color = speedToColor(oSpeed * 1.94384449);
        if (greyed === true) {
            // color = "rgba(128,128,128)";
            color = GREYED_COLOR;
        } else {
            
        }
        return new L.divIcon({
            iconSize: [ARROW_WIDTH, ARROW_HEIGHT],
            iconAnchor: [ARROW_WIDTH / 2, ARROW_HEIGHT / 2],
            className: '',
            html: (
                `<svg
                    xmlns="http://www.w3.org/2000/svg" 
                    xmlns:xlink= "http://www.w3.org/1999/xlink" 
                    width="${ARROW_WIDTH}" 
                    height="${ARROW_HEIGHT}"
                    style="${rotateGen(degree)} padding: 0; margin: 0; overflow: visible;">    
                    <polygon 
                        transform="scale(${scale ? 1.2 : 1.0})"
                        points="${ARROW_A[0]} ${ARROW_A[1]}, ${ARROW_B[0]} ${ARROW_B[1]}, ${ARROW_C[0]} ${ARROW_C[1]}"
                        style="fill:${color};stroke:#000000;" />
                </svg>`
            )
        });
    }
};

const iconGen = (speed, deg, scale, greyed) => {
    return svgSource(speed, Math.floor(deg), scale, greyed);
};

const greenArrow = iconGen(greenArrowSvg, arrowDimensions);
const greenSquare = iconGen(greenSquareSvg, squareDimensions);
const yellowArrow = iconGen(yellowArrowSvg, arrowDimensions);
const yellowSquare = iconGen(yellowSquareSvg, squareDimensions);
const redArrow = iconGen(redArrowSvg, arrowDimensions);
const redSquare = iconGen(redSquareSvg, squareDimensions);
const whiteArrow = iconGen(whiteArrowSvg, arrowDimensions);
const whiteSquare = iconGen(whiteSquareSvg, squareDimensions);

export {
    iconGen,
    greenArrow,
    greenSquare,
    yellowArrow,
    yellowSquare,
    redArrow,
    redSquare,
    whiteArrow,
    whiteSquare
};