// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Printf = require("bs-platform/lib/js/printf.js");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var TimeagoJs = require("timeago.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var LocationJs = require("../../Util/Location.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");

function latToDegree(prim) {
  return LocationJs.latToDegree(prim);
}

function lonToDegree(prim) {
  return LocationJs.lonToDegree(prim);
}

function str(prim) {
  return prim;
}

var component = ReasonReact.reducerComponent("MapVesselSearchItemSelected");

function make(name, imo, timestamp, dateTime, mail, track, file, connectionType, heading, speed, latitude, longitude, _children) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */(function (self) {
              var interval = setInterval((function (param) {
                      return Curry._1(self[/* send */3], /* Tick */0);
                    }), 500);
              return Curry._1(self[/* send */3], /* SetInterval */[interval]);
            }),
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */(function (self) {
              var match = self[/* state */1][/* tickInterval */1];
              if (match !== undefined) {
                clearInterval(Caml_option.valFromOption(match));
                return /* () */0;
              } else {
                throw [
                      Caml_builtin_exceptions.match_failure,
                      /* tuple */[
                        "MapVesselSearchItemSelected.re",
                        37,
                        25
                      ]
                    ];
              }
            }),
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (self) {
              var tracks = track ? React.createElement("div", {
                      className: "map-vessel-search-info-box"
                    }, React.createElement("span", {
                          className: "left"
                        }, "Track:"), "API key assigned") : "";
              var files = file ? React.createElement("div", {
                      className: "map-vessel-search-info-box"
                    }, React.createElement("span", {
                          className: "left"
                        }, "File:"), "API key assigned") : "";
              var mails = mail ? React.createElement("div", {
                      className: "map-vessel-search-info-box"
                    }, React.createElement("span", {
                          className: "left"
                        }, "Mail:"), "E-mail address defined") : "";
              var services = mail || track || file ? React.createElement("div", {
                      className: "map-vessel-search-info-list"
                    }, React.createElement("div", {
                          className: "map-vessel-search-info-box"
                        }, React.createElement("strong", undefined, "Services")), tracks, files, mails) : "";
              return React.createElement("div", {
                          className: "map-vessel-search-info"
                        }, React.createElement("div", {
                              className: "map-vessel-search-info-list"
                            }, React.createElement("div", {
                                  className: "map-vessel-search-info-box"
                                }, React.createElement("div", {
                                      className: "mvsib-container"
                                    }, React.createElement("div", {
                                          className: "left"
                                        }, "Last updated:"), React.createElement("div", {
                                          className: "right"
                                        }, React.createElement("span", undefined, self[/* state */1][/* ago */0]), React.createElement("br", undefined), React.createElement("span", undefined, Caml_array.caml_array_get(timestamp.split("."), 0) + " UTC")), React.createElement("div", {
                                          className: "clearboth"
                                        }))), React.createElement("div", {
                                  className: "map-vessel-search-info-box"
                                }, React.createElement("div", {
                                      className: "mvsib-container"
                                    }, React.createElement("div", {
                                          className: "left"
                                        }, "Conn. type:"), React.createElement("div", {
                                          className: "right"
                                        }, connectionType), React.createElement("div", {
                                          className: "clearboth"
                                        }))), React.createElement("div", {
                                  className: "map-vessel-search-info-box"
                                }, React.createElement("div", {
                                      className: "mvsib-container"
                                    }, React.createElement("div", {
                                          className: "left"
                                        }, "Location:"), React.createElement("div", {
                                          className: "right"
                                        }, React.createElement("span", undefined, LocationJs.latToDegree(latitude)), React.createElement("br", undefined), React.createElement("span", undefined, LocationJs.lonToDegree(longitude))), React.createElement("div", {
                                          className: "clearboth"
                                        }))), React.createElement("div", {
                                  className: "map-vessel-search-info-box"
                                }, React.createElement("div", {
                                      className: "mvsib-container"
                                    }, React.createElement("div", {
                                          className: "left"
                                        }, "Course (COG):"), React.createElement("div", {
                                          className: "right"
                                        }, Curry._1(Printf.sprintf(/* Format */[
                                                  /* Float */Block.__(8, [
                                                      /* Float_f */0,
                                                      /* No_padding */0,
                                                      /* Lit_precision */[0],
                                                      /* End_of_format */0
                                                    ]),
                                                  "%.0f"
                                                ]), heading), "°"), React.createElement("div", {
                                          className: "clearboth"
                                        }))), React.createElement("div", {
                                  className: "map-vessel-search-info-box"
                                }, React.createElement("div", {
                                      className: "mvsib-container"
                                    }, React.createElement("div", {
                                          className: "left"
                                        }, "Speed (SOG):"), React.createElement("div", {
                                          className: "right"
                                        }, Curry._1(Printf.sprintf(/* Format */[
                                                  /* Float */Block.__(8, [
                                                      /* Float_f */0,
                                                      /* No_padding */0,
                                                      /* Lit_precision */[2],
                                                      /* String_literal */Block.__(11, [
                                                          " kn",
                                                          /* End_of_format */0
                                                        ])
                                                    ]),
                                                  "%.2f kn"
                                                ]), speed * 1.94384449)), React.createElement("div", {
                                          className: "clearboth"
                                        })))), services);
            }),
          /* initialState */(function (param) {
              return /* record */[
                      /* ago */TimeagoJs.format(dateTime),
                      /* tickInterval */undefined
                    ];
            }),
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */(function (action, state) {
              if (action) {
                return /* Update */Block.__(0, [/* record */[
                            /* ago */state[/* ago */0],
                            /* tickInterval */Caml_option.some(action[0])
                          ]]);
              } else {
                return /* Update */Block.__(0, [/* record */[
                            /* ago */TimeagoJs.format(dateTime),
                            /* tickInterval */state[/* tickInterval */1]
                          ]]);
              }
            }),
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

var jsComponent = ReasonReact.wrapReasonForJs(component, (function (jsProps) {
        return make(jsProps.name, jsProps.imo, jsProps.timestamp, jsProps.dateTime, jsProps.mail, jsProps.track, jsProps.file, jsProps.connectionType, jsProps.heading, jsProps.speed, jsProps.latitude, jsProps.longitude, /* array */[]);
      }));

exports.latToDegree = latToDegree;
exports.lonToDegree = lonToDegree;
exports.str = str;
exports.component = component;
exports.make = make;
exports.jsComponent = jsComponent;
/* component Not a pure module */
