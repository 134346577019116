// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Dialog = require("./Dialog");
var Loading = require("./Loading");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var UserData = require("../Util/UserData");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");

function isValidEmail(email) {
  return (/\S+@\S+\.\S+/).test(email);
}

function str(prim) {
  return prim;
}

function findo(fn, ls) {
  var value;
  try {
    value = List.find(fn, ls);
  }
  catch (exn){
    if (exn === Caml_builtin_exceptions.not_found) {
      return ;
    } else {
      throw exn;
    }
  }
  return Caml_option.some(value);
}

var component = ReasonReact.reducerComponent("InviteForm");

function make(store, _children) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */(function (self) {
              return Curry._1(self[/* send */3], /* Radios */Block.__(6, [/* Load */0]));
            }),
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (self) {
              var match = self[/* state */1][/* radios */5];
              var tmp;
              tmp = typeof match === "number" || match.tag !== /* Done */1 ? React.createElement("tr", undefined) : $$Array.of_list(List.map((function (x) {
                            return React.createElement("tr", {
                                        key: x[/* uuid */3],
                                        value: x[/* uuid */3]
                                      }, React.createElement("td", {
                                            style: {
                                              marginLeft: "10px",
                                              width: "10%"
                                            }
                                          }, React.createElement("input", {
                                                checked: List.mem(x, self[/* state */1][/* selectedRadios */4]),
                                                type: "checkbox",
                                                onChange: (function (e) {
                                                    return Curry._1(self[/* send */3], e.target.checked ? /* SelectRadio */Block.__(5, [x]) : /* UnSelectRadio */Block.__(4, [x]));
                                                  })
                                              })), React.createElement("td", {
                                            style: {
                                              marginLeft: "10px",
                                              width: "50%"
                                            }
                                          }, x[/* name */5]), React.createElement("td", {
                                            style: {
                                              marginRight: "25px",
                                              textAlign: "right",
                                              width: "40%"
                                            }
                                          }, "#" + UserData.getPrintableSid(x)));
                          }), List.filter((function (x) {
                                  if (self[/* state */1][/* radiosFilter */0] === "") {
                                    return true;
                                  } else {
                                    return x[/* name */5].toLowerCase().indexOf(self[/* state */1][/* radiosFilter */0].toLowerCase()) >= 0;
                                  }
                                }))(match[0])));
              return React.createElement("div", {
                          className: "editable row"
                        }, self[/* state */1][/* radios */5] === /* Loading */1 ? ReasonReact.element(undefined, undefined, Loading.make(/* array */[])) : React.createElement("div", undefined), self[/* state */1][/* promptVisible */1] ? ReasonReact.element(undefined, undefined, Dialog.make(300, 350, (function (param) {
                                      Curry._1(self[/* send */3], /* Prompt */Block.__(1, [false]));
                                      return Curry._1(self[/* send */3], /* Invite */Block.__(7, [/* Load */0]));
                                    }), (function (param) {
                                      return Curry._1(self[/* send */3], /* Prompt */Block.__(1, [false]));
                                    }), /* array */[React.createElement("div", {
                                          className: "user-page-dialog"
                                        }, React.createElement("p", undefined, "You are about to invite a new customer"), React.createElement("p", undefined, React.createElement("strong", undefined, self[/* state */1][/* name */3])), React.createElement("p", undefined, "with email address"), React.createElement("p", undefined, React.createElement("strong", undefined, self[/* state */1][/* email */2])), React.createElement("p", undefined, "and there are ", React.createElement("strong", undefined, String(List.length(self[/* state */1][/* selectedRadios */4]))), " vessels selected for the customer."), React.createElement("p", undefined, React.createElement("strong", undefined, "Are you sure?")))])) : React.createElement("div", undefined), React.createElement("div", {
                              className: "col-lg-12 col-md-12 col-sm-12"
                            }, React.createElement("form", {
                                  className: "editable-form",
                                  onSubmit: (function (e) {
                                      e.preventDefault();
                                      return Curry._1(self[/* send */3], /* Prompt */Block.__(1, [true]));
                                    })
                                }, React.createElement("fieldset", undefined, React.createElement("div", {
                                          className: "row responsive-label"
                                        }, React.createElement("div", {
                                              className: "col-sm-12 col-md-3"
                                            }, React.createElement("label", {
                                                  className: "doc",
                                                  htmlFor: "sf1-text"
                                                }, "Name")), React.createElement("div", {
                                              className: "col-sm-12 col-md-3"
                                            }, React.createElement("input", {
                                                  className: "doc",
                                                  id: "sf1-text",
                                                  autoComplete: "off",
                                                  placeholder: "Customer name",
                                                  type: "text",
                                                  value: self[/* state */1][/* name */3],
                                                  onChange: (function (e) {
                                                      return Curry._1(self[/* send */3], /* UpdateName */Block.__(3, [e.target.value]));
                                                    })
                                                }))), React.createElement("div", {
                                          className: "row responsive-label"
                                        }, React.createElement("div", {
                                              className: "col-sm-12 col-md-3"
                                            }, React.createElement("label", {
                                                  className: "doc",
                                                  htmlFor: "sf2-text"
                                                }, "Email")), React.createElement("div", {
                                              className: "col-sm-12 col-md-3"
                                            }, React.createElement("input", {
                                                  className: "doc",
                                                  id: "sf2-text",
                                                  autoComplete: "off",
                                                  placeholder: "Customer admin email",
                                                  type: "text",
                                                  value: self[/* state */1][/* email */2],
                                                  onChange: (function (e) {
                                                      return Curry._1(self[/* send */3], /* UpdateEmail */Block.__(2, [e.target.value]));
                                                    })
                                                })))), React.createElement("fieldset", undefined, React.createElement("div", {
                                          className: "vessel-list-row row"
                                        }, React.createElement("div", {
                                              className: "vessel-list-column col-sm-12 col-md-12"
                                            }, React.createElement("fieldset", undefined, React.createElement("div", {
                                                      className: "row responsive-label"
                                                    }, React.createElement("div", {
                                                          className: "col-sm-12 col-md-12"
                                                        }, React.createElement("label", {
                                                              className: "doc",
                                                              htmlFor: "sf2-text"
                                                            }, "Add vessels to customer")), React.createElement("div", {
                                                          className: "col-sm-12 col-md-3"
                                                        }, React.createElement("input", {
                                                              className: "doc",
                                                              id: "sf2-text",
                                                              autoComplete: "off",
                                                              placeholder: "Filter the list below",
                                                              type: "text",
                                                              value: self[/* state */1][/* radiosFilter */0],
                                                              onChange: (function (e) {
                                                                  return Curry._1(self[/* send */3], /* UpdateRadiosFilter */Block.__(0, [e.target.value]));
                                                                })
                                                            })))), React.createElement("table", {
                                                  className: "knl-striped"
                                                }, React.createElement("tbody", undefined, tmp))))), React.createElement("button", {
                                      disabled: self[/* state */1][/* name */3].length === 0 || !isValidEmail(self[/* state */1][/* email */2])
                                    }, "Invite"))));
            }),
          /* initialState */(function (param) {
              return /* record */[
                      /* radiosFilter */"",
                      /* promptVisible */false,
                      /* email */"",
                      /* name */"",
                      /* selectedRadios : [] */0,
                      /* radios : Idle */0,
                      /* invite : Idle */0
                    ];
            }),
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */(function (action, state) {
              switch (action.tag | 0) {
                case /* UpdateRadiosFilter */0 :
                    return /* Update */Block.__(0, [/* record */[
                                /* radiosFilter */action[0],
                                /* promptVisible */state[/* promptVisible */1],
                                /* email */state[/* email */2],
                                /* name */state[/* name */3],
                                /* selectedRadios */state[/* selectedRadios */4],
                                /* radios */state[/* radios */5],
                                /* invite */state[/* invite */6]
                              ]]);
                case /* Prompt */1 :
                    return /* Update */Block.__(0, [/* record */[
                                /* radiosFilter */state[/* radiosFilter */0],
                                /* promptVisible */action[0],
                                /* email */state[/* email */2],
                                /* name */state[/* name */3],
                                /* selectedRadios */state[/* selectedRadios */4],
                                /* radios */state[/* radios */5],
                                /* invite */state[/* invite */6]
                              ]]);
                case /* UpdateEmail */2 :
                    return /* Update */Block.__(0, [/* record */[
                                /* radiosFilter */state[/* radiosFilter */0],
                                /* promptVisible */state[/* promptVisible */1],
                                /* email */action[0],
                                /* name */state[/* name */3],
                                /* selectedRadios */state[/* selectedRadios */4],
                                /* radios */state[/* radios */5],
                                /* invite */state[/* invite */6]
                              ]]);
                case /* UpdateName */3 :
                    return /* Update */Block.__(0, [/* record */[
                                /* radiosFilter */state[/* radiosFilter */0],
                                /* promptVisible */state[/* promptVisible */1],
                                /* email */state[/* email */2],
                                /* name */action[0],
                                /* selectedRadios */state[/* selectedRadios */4],
                                /* radios */state[/* radios */5],
                                /* invite */state[/* invite */6]
                              ]]);
                case /* UnSelectRadio */4 :
                    var radio = action[0];
                    return /* Update */Block.__(0, [/* record */[
                                /* radiosFilter */state[/* radiosFilter */0],
                                /* promptVisible */state[/* promptVisible */1],
                                /* email */state[/* email */2],
                                /* name */state[/* name */3],
                                /* selectedRadios */List.filter((function (x) {
                                          return Caml_obj.caml_notequal(x, radio);
                                        }))(state[/* selectedRadios */4]),
                                /* radios */state[/* radios */5],
                                /* invite */state[/* invite */6]
                              ]]);
                case /* SelectRadio */5 :
                    return /* Update */Block.__(0, [/* record */[
                                /* radiosFilter */state[/* radiosFilter */0],
                                /* promptVisible */state[/* promptVisible */1],
                                /* email */state[/* email */2],
                                /* name */state[/* name */3],
                                /* selectedRadios : :: */[
                                  action[0],
                                  state[/* selectedRadios */4]
                                ],
                                /* radios */state[/* radios */5],
                                /* invite */state[/* invite */6]
                              ]]);
                case /* Radios */6 :
                    var match = action[0];
                    if (typeof match === "number") {
                      return /* UpdateWithSideEffects */Block.__(2, [
                                /* record */[
                                  /* radiosFilter */state[/* radiosFilter */0],
                                  /* promptVisible */state[/* promptVisible */1],
                                  /* email */state[/* email */2],
                                  /* name */state[/* name */3],
                                  /* selectedRadios */state[/* selectedRadios */4],
                                  /* radios : Loading */1,
                                  /* invite */state[/* invite */6]
                                ],
                                (function (self) {
                                    UserData.getVessels(/* () */0).then((function (result) {
                                            return Promise.resolve(Curry._1(self[/* send */3], /* Radios */Block.__(6, [result])));
                                          }));
                                    return /* () */0;
                                  })
                              ]);
                    } else if (match.tag) {
                      var match$1 = match[0];
                      if (match$1) {
                        return /* Update */Block.__(0, [/* record */[
                                    /* radiosFilter */state[/* radiosFilter */0],
                                    /* promptVisible */state[/* promptVisible */1],
                                    /* email */state[/* email */2],
                                    /* name */state[/* name */3],
                                    /* selectedRadios */state[/* selectedRadios */4],
                                    /* radios : Failed */Block.__(0, [match$1[0]]),
                                    /* invite */state[/* invite */6]
                                  ]]);
                      } else {
                        return /* UpdateWithSideEffects */Block.__(2, [
                                  /* record */[
                                    /* radiosFilter */state[/* radiosFilter */0],
                                    /* promptVisible */state[/* promptVisible */1],
                                    /* email */state[/* email */2],
                                    /* name */state[/* name */3],
                                    /* selectedRadios */state[/* selectedRadios */4],
                                    /* radios : Failed */Block.__(0, ["Unauthorized"]),
                                    /* invite */state[/* invite */6]
                                  ],
                                  (function (_self) {
                                      return Curry._1(store[/* logout */2], /* () */0);
                                    })
                                ]);
                      }
                    } else {
                      return /* Update */Block.__(0, [/* record */[
                                  /* radiosFilter */state[/* radiosFilter */0],
                                  /* promptVisible */state[/* promptVisible */1],
                                  /* email */state[/* email */2],
                                  /* name */state[/* name */3],
                                  /* selectedRadios */state[/* selectedRadios */4],
                                  /* radios : Done */Block.__(1, [match[0]]),
                                  /* invite */state[/* invite */6]
                                ]]);
                    }
                case /* Invite */7 :
                    var match$2 = action[0];
                    if (typeof match$2 === "number") {
                      return /* UpdateWithSideEffects */Block.__(2, [
                                /* record */[
                                  /* radiosFilter */state[/* radiosFilter */0],
                                  /* promptVisible */state[/* promptVisible */1],
                                  /* email */state[/* email */2],
                                  /* name */state[/* name */3],
                                  /* selectedRadios */state[/* selectedRadios */4],
                                  /* radios */state[/* radios */5],
                                  /* invite : Loading */1
                                ],
                                (function (self) {
                                    UserData.inviteCustomer(state[/* email */2], state[/* name */3], List.map((function (x) {
                                                  return x[/* uuid */3];
                                                }), state[/* selectedRadios */4])).then((function (result) {
                                            return Promise.resolve(Curry._1(self[/* send */3], /* Invite */Block.__(7, [result])));
                                          }));
                                    return /* () */0;
                                  })
                              ]);
                    } else if (match$2.tag) {
                      var match$3 = match$2[0];
                      if (match$3) {
                        return /* Update */Block.__(0, [/* record */[
                                    /* radiosFilter */state[/* radiosFilter */0],
                                    /* promptVisible */state[/* promptVisible */1],
                                    /* email */state[/* email */2],
                                    /* name */state[/* name */3],
                                    /* selectedRadios */state[/* selectedRadios */4],
                                    /* radios */state[/* radios */5],
                                    /* invite : Failed */Block.__(0, [match$3[0]])
                                  ]]);
                      } else {
                        return /* UpdateWithSideEffects */Block.__(2, [
                                  /* record */[
                                    /* radiosFilter */state[/* radiosFilter */0],
                                    /* promptVisible */state[/* promptVisible */1],
                                    /* email */state[/* email */2],
                                    /* name */state[/* name */3],
                                    /* selectedRadios */state[/* selectedRadios */4],
                                    /* radios */state[/* radios */5],
                                    /* invite : Failed */Block.__(0, ["Unauthorized"])
                                  ],
                                  (function (_self) {
                                      return Curry._1(store[/* logout */2], /* () */0);
                                    })
                                ]);
                      }
                    } else {
                      return /* Update */Block.__(0, [/* record */[
                                  /* radiosFilter */state[/* radiosFilter */0],
                                  /* promptVisible */state[/* promptVisible */1],
                                  /* email */state[/* email */2],
                                  /* name */state[/* name */3],
                                  /* selectedRadios */state[/* selectedRadios */4],
                                  /* radios */state[/* radios */5],
                                  /* invite : Done */Block.__(1, [match$2[0]])
                                ]]);
                    }
                
              }
            }),
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.isValidEmail = isValidEmail;
exports.str = str;
exports.findo = findo;
exports.component = component;
exports.make = make;
/* component Not a pure module */
