// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Dialog = require("./Dialog");
var UserData = require("../Util/UserData");
var ReasonReact = require("reason-react/src/ReasonReact.js");

function isValidEmail(email) {
  return (/\S+@\S+\.\S+/).test(email);
}

function str(prim) {
  return prim;
}

var component = ReasonReact.reducerComponent("InviteForm");

function make(store, _children) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (self) {
              var match = self[/* state */1][/* sid */2];
              return React.createElement("div", {
                          className: "editable row"
                        }, self[/* state */1][/* promptVisible */4] ? ReasonReact.element(undefined, undefined, Dialog.make(300, 150, (function (param) {
                                      Curry._1(self[/* send */3], /* Prompt */Block.__(4, [false]));
                                      return Curry._1(self[/* send */3], /* Vessel */Block.__(5, [/* Load */0]));
                                    }), (function (param) {
                                      return Curry._1(self[/* send */3], /* Prompt */Block.__(4, [false]));
                                    }), /* array */[React.createElement("div", {
                                          className: "user-page-dialog"
                                        }, React.createElement("p", undefined, React.createElement("strong", undefined, "Are you sure you want to add this vessel and radio?")))])) : React.createElement("div", undefined), React.createElement("div", {
                              className: "col-lg-12 col-md-12 col-sm-12"
                            }, React.createElement("form", {
                                  className: "editable-form",
                                  onSubmit: (function (e) {
                                      e.preventDefault();
                                      return Curry._1(self[/* send */3], /* Prompt */Block.__(4, [true]));
                                    })
                                }, React.createElement("fieldset", undefined, React.createElement("div", {
                                          className: "row responsive-label"
                                        }, React.createElement("div", {
                                              className: "col-sm-12 col-md-3"
                                            }, React.createElement("label", {
                                                  className: "doc",
                                                  htmlFor: "sf1-text"
                                                }, "Name")), React.createElement("div", {
                                              className: "col-sm-12 col-md-3"
                                            }, React.createElement("input", {
                                                  className: "doc",
                                                  id: "sf1-text",
                                                  autoComplete: "off",
                                                  placeholder: "Vessel / place name",
                                                  type: "text",
                                                  value: self[/* state */1][/* name */0],
                                                  onChange: (function (e) {
                                                      return Curry._1(self[/* send */3], /* UpdateName */Block.__(0, [e.target.value]));
                                                    })
                                                }))), React.createElement("div", {
                                          className: "row responsive-label"
                                        }, React.createElement("div", {
                                              className: "col-sm-12 col-md-3"
                                            }, React.createElement("label", {
                                                  className: "doc",
                                                  htmlFor: "sf2-text"
                                                }, "SID")), React.createElement("div", {
                                              className: "col-sm-12 col-md-3"
                                            }, React.createElement("input", {
                                                  className: "doc",
                                                  id: "sf2-text",
                                                  autoComplete: "off",
                                                  placeholder: "Radio number",
                                                  type: "number",
                                                  value: match !== undefined ? String(match) : "",
                                                  onChange: (function (e) {
                                                      return Curry._1(self[/* send */3], /* UpdateSid */Block.__(3, [e.target.value]));
                                                    })
                                                }))), React.createElement("div", {
                                          className: "row responsive-label"
                                        }, React.createElement("div", {
                                              className: "col-sm-12 col-md-3"
                                            }, React.createElement("label", {
                                                  className: "doc",
                                                  htmlFor: "sf2-text"
                                                }, "IMO")), React.createElement("div", {
                                              className: "col-sm-12 col-md-3"
                                            }, React.createElement("input", {
                                                  className: "doc",
                                                  id: "sf2-text",
                                                  autoComplete: "off",
                                                  placeholder: "IMO",
                                                  type: "text",
                                                  value: self[/* state */1][/* imo */1],
                                                  onChange: (function (e) {
                                                      return Curry._1(self[/* send */3], /* UpdateImo */Block.__(1, [e.target.value]));
                                                    })
                                                }))), React.createElement("div", {
                                          className: "row responsive-label"
                                        }, React.createElement("div", {
                                              className: "col-sm-12 col-md-3"
                                            }, React.createElement("label", {
                                                  className: "doc",
                                                  htmlFor: "sf2-text"
                                                }, "PID")), React.createElement("div", {
                                              className: "col-sm-12 col-md-3"
                                            }, React.createElement("input", {
                                                  className: "doc",
                                                  id: "sf2-text",
                                                  autoComplete: "off",
                                                  placeholder: "Place of use identifier",
                                                  type: "text",
                                                  value: self[/* state */1][/* pid */3],
                                                  onChange: (function (e) {
                                                      return Curry._1(self[/* send */3], /* UpdatePid */Block.__(2, [e.target.value]));
                                                    })
                                                }))), React.createElement("button", {
                                          disabled: self[/* state */1][/* name */0].length === 0 || self[/* state */1][/* pid */3].length === 0 || self[/* state */1][/* sid */2] === undefined
                                        }, "ADD")))));
            }),
          /* initialState */(function (param) {
              return /* record */[
                      /* name */"",
                      /* imo */"",
                      /* sid */undefined,
                      /* pid */"",
                      /* promptVisible */false,
                      /* vessel : Idle */0
                    ];
            }),
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */(function (action, state) {
              switch (action.tag | 0) {
                case /* UpdateName */0 :
                    return /* Update */Block.__(0, [/* record */[
                                /* name */action[0],
                                /* imo */state[/* imo */1],
                                /* sid */state[/* sid */2],
                                /* pid */state[/* pid */3],
                                /* promptVisible */state[/* promptVisible */4],
                                /* vessel */state[/* vessel */5]
                              ]]);
                case /* UpdateImo */1 :
                    return /* Update */Block.__(0, [/* record */[
                                /* name */state[/* name */0],
                                /* imo */action[0],
                                /* sid */state[/* sid */2],
                                /* pid */state[/* pid */3],
                                /* promptVisible */state[/* promptVisible */4],
                                /* vessel */state[/* vessel */5]
                              ]]);
                case /* UpdatePid */2 :
                    return /* Update */Block.__(0, [/* record */[
                                /* name */state[/* name */0],
                                /* imo */state[/* imo */1],
                                /* sid */state[/* sid */2],
                                /* pid */action[0],
                                /* promptVisible */state[/* promptVisible */4],
                                /* vessel */state[/* vessel */5]
                              ]]);
                case /* UpdateSid */3 :
                    return /* Update */Block.__(0, [/* record */[
                                /* name */state[/* name */0],
                                /* imo */state[/* imo */1],
                                /* sid */action[0],
                                /* pid */state[/* pid */3],
                                /* promptVisible */state[/* promptVisible */4],
                                /* vessel */state[/* vessel */5]
                              ]]);
                case /* Prompt */4 :
                    return /* Update */Block.__(0, [/* record */[
                                /* name */state[/* name */0],
                                /* imo */state[/* imo */1],
                                /* sid */state[/* sid */2],
                                /* pid */state[/* pid */3],
                                /* promptVisible */action[0],
                                /* vessel */state[/* vessel */5]
                              ]]);
                case /* Vessel */5 :
                    var match = action[0];
                    if (typeof match === "number") {
                      return /* UpdateWithSideEffects */Block.__(2, [
                                /* record */[
                                  /* name */state[/* name */0],
                                  /* imo */state[/* imo */1],
                                  /* sid */state[/* sid */2],
                                  /* pid */state[/* pid */3],
                                  /* promptVisible */state[/* promptVisible */4],
                                  /* vessel : Loading */1
                                ],
                                (function (self) {
                                    var match = self[/* state */1][/* sid */2];
                                    if (match !== undefined) {
                                      UserData.addVessel(self[/* state */1][/* pid */3], match, self[/* state */1][/* imo */1], self[/* state */1][/* name */0]).then((function (result) {
                                              return Promise.resolve(Curry._1(self[/* send */3], /* Vessel */Block.__(5, [result])));
                                            }));
                                      return /* () */0;
                                    } else {
                                      return Curry._1(self[/* send */3], /* Vessel */Block.__(5, [/* LoadFailed */Block.__(1, [/* GeneralError */["Empty sid?"]])]));
                                    }
                                  })
                              ]);
                    } else if (match.tag) {
                      var match$1 = match[0];
                      if (match$1) {
                        var error = match$1[0];
                        return /* UpdateWithSideEffects */Block.__(2, [
                                  /* record */[
                                    /* name */"",
                                    /* imo */"",
                                    /* sid */undefined,
                                    /* pid */"",
                                    /* promptVisible */state[/* promptVisible */4],
                                    /* vessel : Failed */Block.__(0, [error])
                                  ],
                                  (function (_self) {
                                      alert(error);
                                      return /* () */0;
                                    })
                                ]);
                      } else {
                        return /* UpdateWithSideEffects */Block.__(2, [
                                  /* record */[
                                    /* name */"",
                                    /* imo */"",
                                    /* sid */undefined,
                                    /* pid */"",
                                    /* promptVisible */state[/* promptVisible */4],
                                    /* vessel : Failed */Block.__(0, ["Unauthorized"])
                                  ],
                                  (function (_self) {
                                      return Curry._1(store[/* logout */2], /* () */0);
                                    })
                                ]);
                      }
                    } else {
                      return /* UpdateWithSideEffects */Block.__(2, [
                                /* record */[
                                  /* name */"",
                                  /* imo */"",
                                  /* sid */undefined,
                                  /* pid */"",
                                  /* promptVisible */state[/* promptVisible */4],
                                  /* vessel : Done */Block.__(1, [match[0]])
                                ],
                                (function (param) {
                                    location.reload();
                                    return /* () */0;
                                  })
                              ]);
                    }
                
              }
            }),
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.isValidEmail = isValidEmail;
exports.str = str;
exports.component = component;
exports.make = make;
/* component Not a pure module */
