// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Axios = require("axios");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function decodeAuthResource(json) {
  return /* record */[
          /* rules */Json_decode.field("rules", (function (param) {
                  return Json_decode.list(Json_decode.string, param);
                }), json),
          /* resource */Json_decode.field("resource", Json_decode.string, json),
          /* actions */Json_decode.field("actions", Json_decode.string, json)
        ];
}

function decodeAuthPermissions(json) {
  return /* record */[
          /* permissionObject */Json_decode.field("object", Json_decode.string, json),
          /* resources */Json_decode.field("resources", (function (param) {
                  return Json_decode.list(decodeAuthResource, param);
                }), json)
        ];
}

function decodeAuthData(json) {
  return /* record */[
          /* user */Json_decode.field("user", Json_decode.string, json),
          /* permissions */Json_decode.field("permissions", (function (param) {
                  return Json_decode.list(decodeAuthPermissions, param);
                }), json)
        ];
}

function decodeAuthStatus(json) {
  return /* record */[/* data */Json_decode.field("data", decodeAuthData, json)];
}

function decodeCustomer(json) {
  return /* record */[/* name */Json_decode.field("name", Json_decode.string, json)];
}

function decodeUserData(json) {
  var match = Json_decode.optional((function (param) {
          return Json_decode.field("name", Json_decode.string, param);
        }), json);
  var match$1 = Json_decode.optional((function (param) {
          return Json_decode.field("username", Json_decode.string, param);
        }), json);
  return /* record */[
          /* name */match !== undefined ? match : "",
          /* active */Json_decode.field("active", Json_decode.bool, json),
          /* email */Json_decode.field("email", Json_decode.string, json),
          /* id */Json_decode.field("id", Json_decode.$$int, json),
          /* mfa */Json_decode.field("mfa", Json_decode.bool, json),
          /* username */match$1 !== undefined ? match$1 : "",
          /* uuid */Json_decode.field("uuid", Json_decode.string, json),
          /* validated */Json_decode.field("validated", Json_decode.bool, json),
          /* customer */$$Array.of_list(List.map((function (item) {
                        return item[/* name */0];
                      }), Json_decode.field("customer", (function (param) {
                            return Json_decode.list(decodeCustomer, param);
                          }), json))).join(", ")
        ];
}

function decodeUsers(json) {
  return /* record */[/* data */Json_decode.field("data", (function (param) {
                  return Json_decode.list(decodeUserData, param);
                }), json)];
}

function decodeUserStatus(json) {
  return /* record */[/* data */Json_decode.field("data", decodeUserData, json)];
}

function decodeRadioData(json) {
  return /* record */[
          /* active */Json_decode.field("active", Json_decode.bool, json),
          /* id */Json_decode.field("id", Json_decode.$$int, json),
          /* knl_base */Json_decode.field("knl_base", Json_decode.bool, json),
          /* pid_code */Json_decode.field("pid_code", Json_decode.string, json),
          /* sid */Json_decode.field("sid", Json_decode.$$int, json),
          /* uuid */Json_decode.field("uuid", Json_decode.string, json)
        ];
}

function decodeRadios(json) {
  return /* record */[/* data */Json_decode.field("data", (function (param) {
                  return Json_decode.list(decodeRadioData, param);
                }), json)];
}

function decodeUsage(json) {
  return /* record */[
          /* token */Json_decode.field("token", Json_decode.string, json),
          /* sea_usage */Json_decode.field("sea_usage", Json_decode.$$int, json),
          /* shore_usage */Json_decode.field("shore_usage", Json_decode.$$int, json)
        ];
}

function decodeUsageList(json) {
  return Json_decode.list(decodeUsage, json);
}

function decodeToken(json) {
  return /* record */[
          /* inserted_at */Json_decode.field("inserted_at", Json_decode.string, json),
          /* updated_at */Json_decode.field("updated_at", Json_decode.string, json),
          /* sid */Json_decode.field("sid", Json_decode.$$int, json),
          /* uuid */Json_decode.field("uuid", Json_decode.string, json)
        ];
}

function decodeDataPlan(json) {
  return /* record */[
          /* name */Json_decode.field("name", Json_decode.string, json),
          /* sea_allowance */Json_decode.field("sea_allowance", Json_decode.$$int, json),
          /* shore_allowance */Json_decode.field("shore_allowance", Json_decode.$$int, json)
        ];
}

function decodeCustomerData(json) {
  return /* record */[
          /* comments */Json_decode.field("comments", Json_decode.string, json),
          /* contact_email */Json_decode.field("contact_email", Json_decode.string, json),
          /* id */Json_decode.field("id", Json_decode.$$int, json),
          /* name */Json_decode.field("name", Json_decode.string, json),
          /* number */Json_decode.field("number", Json_decode.string, json),
          /* tech_email */Json_decode.field("tech_email", Json_decode.string, json),
          /* uuid */Json_decode.field("uuid", Json_decode.string, json)
        ];
}

function decodeCustomers(param) {
  return Json_decode.field("data", (function (param) {
                return Json_decode.list(decodeCustomerData, param);
              }), param);
}

function decodeVessel(json) {
  return /* record */[
          /* active */Json_decode.field("active", Json_decode.bool, json),
          /* id */Json_decode.field("id", Json_decode.$$int, json),
          /* pid_code */Json_decode.field("pid_code", Json_decode.string, json),
          /* uuid */Json_decode.field("uuid", Json_decode.string, json),
          /* imo */Json_decode.field("imo", Json_decode.string, json),
          /* name */Json_decode.field("name", Json_decode.string, json),
          /* radio */Json_decode.optional((function (param) {
                  return Json_decode.field("radio", (function (param) {
                                return Json_decode.list(decodeRadioData, param);
                              }), param);
                }), json)
        ];
}

function decodeSubscription(json) {
  return /* record */[
          /* billing_token */Json_decode.field("billing_token", Json_decode.string, json),
          /* inserted_at */Json_decode.field("inserted_at", Json_decode.string, json),
          /* data_plan */Json_decode.field("data_plan", decodeDataPlan, json),
          /* vessels */Json_decode.field("vessels", (function (param) {
                  return Json_decode.list(decodeVessel, param);
                }), json),
          /* tokens */Json_decode.field("tokens", (function (param) {
                  return Json_decode.list(decodeToken, param);
                }), json)
        ];
}

function decodeSubscriptions(param) {
  return Json_decode.field("data", (function (param) {
                return Json_decode.list(decodeSubscription, param);
              }), param);
}

function decodeVessels(param) {
  return Json_decode.field("data", (function (param) {
                return Json_decode.list(decodeVessel, param);
              }), param);
}

function decodeCustomersVessel(json) {
  return /* record */[
          /* id */Json_decode.field("id", Json_decode.$$int, json),
          /* contact_email */Json_decode.field("contact_email", Json_decode.string, json),
          /* uuid */Json_decode.field("uuid", Json_decode.string, json),
          /* name */Json_decode.field("name", Json_decode.string, json),
          /* vessels */Json_decode.field("vessels", (function (param) {
                  return Json_decode.list(decodeVessel, param);
                }), json)
        ];
}

function decodeCustomersVessels(param) {
  return Json_decode.field("data", (function (param) {
                return Json_decode.list(decodeCustomersVessel, param);
              }), param);
}

var Decode = {
  decodeAuthResource: decodeAuthResource,
  decodeAuthPermissions: decodeAuthPermissions,
  decodeAuthData: decodeAuthData,
  decodeAuthStatus: decodeAuthStatus,
  decodeCustomer: decodeCustomer,
  decodeUserData: decodeUserData,
  decodeUsers: decodeUsers,
  decodeUserStatus: decodeUserStatus,
  decodeRadioData: decodeRadioData,
  decodeRadios: decodeRadios,
  decodeUsage: decodeUsage,
  decodeUsageList: decodeUsageList,
  decodeToken: decodeToken,
  decodeDataPlan: decodeDataPlan,
  decodeCustomerData: decodeCustomerData,
  decodeCustomers: decodeCustomers,
  decodeVessel: decodeVessel,
  decodeSubscription: decodeSubscription,
  decodeSubscriptions: decodeSubscriptions,
  decodeVessels: decodeVessels,
  decodeCustomersVessel: decodeCustomersVessel,
  decodeCustomersVessels: decodeCustomersVessels
};

function getPrintableSid(vessel) {
  var match = vessel[/* radio */6];
  if (match !== undefined) {
    return $$Array.of_list(List.map((function (prim) {
                        return String(prim);
                      }), List.map((function (x) {
                            return x[/* sid */4];
                          }), match))).join(", ");
  } else {
    return "";
  }
}

function loginInfo(username, password, rememberMe) {
  return {
          data: {
            username: username,
            password: password,
            remember_me: rememberMe
          }
        };
}

var axiosConfig = {
  baseURL: window.config.baseUrl,
  withCredentials: true,
  xsrfCookieName: "x-xsrf-token",
  xsrfHeaderName: "x-xsrf-token"
};

function authenticate(username, password, rememberMe) {
  return Axios.post("/api/session", loginInfo(username, password, rememberMe), axiosConfig).then((function (response) {
                  return response.data;
                })).then((function (data) {
                return Promise.resolve(decodeAuthStatus(data));
              }));
}

function getUserInfo(uuid) {
  return Axios.get("/api/users/" + uuid, axiosConfig).then((function (response) {
                  return response.data;
                })).then((function (data) {
                return Promise.resolve(decodeUserStatus(data));
              }));
}

function mkError(error) {
  console.log(error);
  var status = error.response.status;
  if (status === 401 || status === 422 || status === 403) {
    return /* Error */Block.__(1, [/* Unauthorized */0]);
  } else {
    return /* Error */Block.__(1, [/* GeneralError */["Something went wrong, try again later"]]);
  }
}

function mkError1(error) {
  console.log(error);
  var status = error.response.status;
  if (status === 401 || status === 422 || status === 403) {
    return /* LoadFailed */Block.__(1, [/* Unauthorized */0]);
  } else {
    return /* LoadFailed */Block.__(1, [/* GeneralError */["Something went wrong, try again later"]]);
  }
}

function login(username, password, rememberMe) {
  return authenticate(username, password, rememberMe).then((function (authStatus) {
                  return getUserInfo(authStatus[/* data */0][/* user */0]).then((function (userStatus) {
                                return Promise.resolve(/* Loaded */Block.__(0, [/* tuple */[
                                                authStatus,
                                                userStatus
                                              ]]));
                              }));
                })).catch((function (err) {
                return Promise.resolve(mkError1(err));
              }));
}

function logout(param) {
  return Axios.delete("/api/session", axiosConfig).then((function (response) {
                    return response.data;
                  })).then((function (param) {
                  return Promise.resolve("ok");
                })).catch((function (err) {
                console.log(err);
                return Promise.resolve(undefined);
              }));
}

function changeEmail(uuid, newEmail, password) {
  return Axios.post("/api/users/" + (uuid + "/email"), {
                  data: {
                    new_email: newEmail,
                    password: password
                  }
                }, axiosConfig).then((function (param) {
                  return Promise.resolve(/* Ok */Block.__(0, ["Ok"]));
                })).catch((function (err) {
                return Promise.resolve(mkError(err));
              }));
}

function changePassword(uuid, newPassword, password) {
  return Axios.post("/api/users/" + (uuid + "/password"), {
                  data: {
                    new_password: newPassword,
                    password: password
                  }
                }, axiosConfig).then((function (param) {
                  return Promise.resolve(/* Ok */Block.__(0, ["Ok"]));
                })).catch((function (err) {
                return Promise.resolve(mkError(err));
              }));
}

function getUsers(param) {
  return Axios.get("/api/users/", axiosConfig).then((function (response) {
                    return response.data;
                  })).then((function (data) {
                  return Promise.resolve(/* Ok */Block.__(0, [decodeUsers(data)]));
                })).catch((function (err) {
                return Promise.resolve(mkError(err));
              }));
}

function deleteUser(uuid) {
  return Axios.delete("/api/users/" + uuid, axiosConfig).then((function (param) {
                  return Promise.resolve(/* Ok */Block.__(0, ["Ok"]));
                })).catch((function (err) {
                return Promise.resolve(mkError(err));
              }));
}

function inviteUser(email) {
  return Axios.post("/api/users", {
                  data: {
                    email: email
                  }
                }, axiosConfig).then((function (param) {
                  return Promise.resolve(/* Ok */Block.__(0, ["Ok"]));
                })).catch((function (err) {
                return Promise.resolve(mkError(err));
              }));
}

function activateUser(name, password, token) {
  return Axios.post("/api/activate", {
                  data: {
                    name: name,
                    password: password,
                    activation_token: token
                  }
                }, axiosConfig).then((function (param) {
                  return Promise.resolve(/* Ok */Block.__(0, ["Ok"]));
                })).catch((function (err) {
                return Promise.resolve(mkError(err));
              }));
}

function resetPasswordUser(email) {
  return Axios.post("/api/password_reset", {
                  data: {
                    email_address: email
                  }
                }, axiosConfig).then((function (param) {
                  return Promise.resolve(/* Ok */Block.__(0, ["Ok"]));
                })).catch((function (err) {
                return Promise.resolve(mkError(err));
              }));
}

function putPasswordUser(token, password) {
  return Axios.put("/api/password_reset", {
                  data: {
                    token: token,
                    password: password
                  }
                }, axiosConfig).then((function (param) {
                  return Promise.resolve(/* Loaded */Block.__(0, ["Ok"]));
                })).catch((function (err) {
                return Promise.resolve(mkError1(err));
              }));
}

function getRadios(param) {
  return Axios.get("/api/radios", axiosConfig).then((function (response) {
                    return response.data;
                  })).then((function (data) {
                  return Promise.resolve(/* Loaded */Block.__(0, [decodeRadios(data)]));
                })).catch((function (err) {
                return Promise.resolve(mkError1(err));
              }));
}

function getVessels(param) {
  return Axios.get("/api/vessels", axiosConfig).then((function (response) {
                    return response.data;
                  })).then((function (data) {
                  return Promise.resolve(/* Loaded */Block.__(0, [decodeVessels(data)]));
                })).catch((function (err) {
                return Promise.resolve(mkError1(err));
              }));
}

function getSubscriptions(param) {
  return Axios.get("/api/subscriptions/", axiosConfig).then((function (response) {
                    return response.data;
                  })).then((function (data) {
                  return Promise.resolve(/* Loaded */Block.__(0, [decodeSubscriptions(data)]));
                })).catch((function (err) {
                return Promise.resolve(mkError1(err));
              }));
}

function getTokens(subscriptions) {
  var findTokens = function (alist) {
    if (alist === /* [] */0) {
      return /* [] */0;
    } else {
      return /* :: */[
              List.map((function (x) {
                      return x[/* uuid */3];
                    }), List.hd(alist)[/* tokens */4]),
              findTokens(List.tl(alist))
            ];
    }
  };
  return List.flatten(findTokens(subscriptions));
}

function add(x, y) {
  return x + y | 0;
}

function getDisplayUsage(subs, usages) {
  return List.map((function (sub) {
                var subscriptionUsage = List.filter((function (y) {
                          return List.mem(y[/* token */0], List.map((function (x) {
                                            return x[/* uuid */3];
                                          }), sub[/* tokens */4]));
                        }))(usages);
                return /* record */[
                        /* billing_token */sub[/* billing_token */0],
                        /* sea_allowance */sub[/* data_plan */2][/* sea_allowance */1],
                        /* shore_allowance */sub[/* data_plan */2][/* shore_allowance */2],
                        /* sea_usage */List.fold_left(add, 0, List.map((function (z) {
                                    return z[/* sea_usage */1];
                                  }), subscriptionUsage)),
                        /* shore_usage */List.fold_left(add, 0, List.map((function (z) {
                                    return z[/* shore_usage */2];
                                  }), subscriptionUsage)),
                        /* name */$$Array.of_list(List.map((function (x) {
                                      return x[/* name */5];
                                    }), sub[/* vessels */3])).join(", ")
                      ];
              }), subs);
}

function getUsage1(subscriptions, month, year) {
  return Axios.get("/api/usage/usage?resource=" + ($$Array.of_list(getTokens(subscriptions)).join(",") + ("&month=" + (String(month) + ("&year=" + String(year))))), axiosConfig).then((function (response) {
                    return response.data;
                  })).then((function (json) {
                  return Promise.resolve(/* Loaded */Block.__(0, [getDisplayUsage(subscriptions, Json_decode.list(decodeUsage, json))]));
                })).catch((function (err) {
                return Promise.resolve(mkError1(err));
              }));
}

function getUsageForSubscriptions(month, year) {
  return getSubscriptions(/* () */0).then((function (result) {
                if (typeof result === "number") {
                  return Promise.resolve(/* Load */0);
                } else if (result.tag) {
                  return Promise.resolve(/* LoadFailed */Block.__(1, [result[0]]));
                } else {
                  return getUsage1(result[0], month, year);
                }
              }));
}

function getCustomers(param) {
  return Axios.get("/api/customers", axiosConfig).then((function (response) {
                    return response.data;
                  })).then((function (data) {
                  return Promise.resolve(/* Loaded */Block.__(0, [decodeCustomers(data)]));
                })).catch((function (err) {
                return Promise.resolve(mkError1(err));
              }));
}

function getCustomersVessels(param) {
  return Axios.get("/api/customers/vessels", axiosConfig).then((function (response) {
                    return response.data;
                  })).then((function (data) {
                  return Promise.resolve(/* Loaded */Block.__(0, [decodeCustomersVessels(data)]));
                })).catch((function (err) {
                return Promise.resolve(mkError1(err));
              }));
}

function inviteCustomer(email, name, vessels) {
  return Axios.post("/api/customers", {
                  data: {
                    tech_email: email,
                    vessels: $$Array.of_list(vessels),
                    number: "",
                    name: name,
                    contact_email: email,
                    comments: "?"
                  }
                }, axiosConfig).then((function (param) {
                  return Promise.resolve(/* Loaded */Block.__(0, ["Ok"]));
                })).catch((function (err) {
                return Promise.resolve(mkError1(err));
              }));
}

function updateCustomerDetails(uuid, email, name) {
  return Axios.put("/api/customers/" + uuid, {
                  data: {
                    tech_email: email,
                    contact_email: email,
                    name: name
                  }
                }, axiosConfig).then((function (param) {
                  return Promise.resolve(/* Loaded */Block.__(0, ["Ok"]));
                })).catch((function (err) {
                return Promise.resolve(mkError1(err));
              }));
}

function addCustomerVessels(uuid, vessels) {
  return Axios.put("/api/customers/" + (uuid + "/vessels"), {
                  data: $$Array.of_list(vessels)
                }, axiosConfig).then((function (param) {
                  return Promise.resolve(/* Loaded */Block.__(0, ["Ok"]));
                })).catch((function (err) {
                return Promise.resolve(mkError1(err));
              }));
}

function addVessel(pid, sid, imo, name) {
  return Axios.post("/api/vessels", {
                  data: {
                    pid: pid,
                    sid: sid,
                    imo: imo,
                    name: name
                  }
                }, axiosConfig).then((function (param) {
                  return Promise.resolve(/* Loaded */Block.__(0, ["Ok"]));
                })).catch((function (err) {
                return Promise.resolve(mkError1(err));
              }));
}

exports.Decode = Decode;
exports.getPrintableSid = getPrintableSid;
exports.loginInfo = loginInfo;
exports.axiosConfig = axiosConfig;
exports.authenticate = authenticate;
exports.getUserInfo = getUserInfo;
exports.mkError = mkError;
exports.mkError1 = mkError1;
exports.login = login;
exports.logout = logout;
exports.changeEmail = changeEmail;
exports.changePassword = changePassword;
exports.getUsers = getUsers;
exports.deleteUser = deleteUser;
exports.inviteUser = inviteUser;
exports.activateUser = activateUser;
exports.resetPasswordUser = resetPasswordUser;
exports.putPasswordUser = putPasswordUser;
exports.getRadios = getRadios;
exports.getVessels = getVessels;
exports.getSubscriptions = getSubscriptions;
exports.getTokens = getTokens;
exports.add = add;
exports.getDisplayUsage = getDisplayUsage;
exports.getUsage1 = getUsage1;
exports.getUsageForSubscriptions = getUsageForSubscriptions;
exports.getCustomers = getCustomers;
exports.getCustomersVessels = getCustomersVessels;
exports.inviteCustomer = inviteCustomer;
exports.updateCustomerDetails = updateCustomerDetails;
exports.addCustomerVessels = addCustomerVessels;
exports.addVessel = addVessel;
/* axiosConfig Not a pure module */
