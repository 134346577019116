// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var KNLCard = require("../Components/KNLCard");
var Password = require("../Util/Password");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var ChangeEmailForm = require("../Components/ChangeEmailForm");
var ChangePasswordForm = require("../Components/ChangePasswordForm");

function str(prim) {
  return prim;
}

var component = ReasonReact.statelessComponent("Profile");

function make(store, _children) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (_self) {
              return React.createElement("div", {
                          className: "row",
                          name: "Profile"
                        }, React.createElement("div", {
                              className: "col-sm-12 col-md-6 col-lg-4"
                            }, ReasonReact.element(undefined, undefined, KNLCard.make(undefined, "BASIC INFO", /* array */[React.createElement("div", {
                                            className: "basic-info"
                                          }, React.createElement("div", {
                                                className: "pair"
                                              }, React.createElement("div", {
                                                    className: "key"
                                                  }, "NAME"), React.createElement("div", {
                                                    className: "value"
                                                  }, store[/* state */0][/* name */4])), React.createElement("div", {
                                                className: "pair"
                                              }, React.createElement("div", {
                                                    className: "key"
                                                  }, "ORGANISATION"), React.createElement("div", {
                                                    className: "value"
                                                  }, store[/* state */0][/* customer */5])), React.createElement("div", {
                                                className: "pair"
                                              }, React.createElement("div", {
                                                    className: "key"
                                                  }, "EMAIL"), React.createElement("div", {
                                                    className: "value",
                                                    name: "current-email"
                                                  }, store[/* state */0][/* email */2])))]))), React.createElement("div", {
                              className: "col-sm-12 col-md-12 col-lg-4"
                            }, ReasonReact.element(undefined, undefined, KNLCard.make(undefined, "SETTINGS", /* array */[React.createElement("div", {
                                            className: "settings"
                                          }, React.createElement("div", {
                                                className: "header"
                                              }, "EMAIL SETTINGS"), React.createElement("div", {
                                                className: "content"
                                              }, "\n                    This email address is used \n                    for contacting you in case \n                    of password reset or other \n                    important messages. Please \n                    ensure the email is correct \n                    and current at all times."), React.createElement("div", {
                                                className: "content"
                                              }, ReasonReact.element(undefined, undefined, ChangeEmailForm.make(store, /* array */[]))), React.createElement("div", {
                                                className: "header"
                                              }, "PASSWORD SETTINGS"), React.createElement("div", {
                                                className: "content"
                                              }, Password.passwordRequirement), React.createElement("div", {
                                                className: "content"
                                              }, ReasonReact.element(undefined, undefined, ChangePasswordForm.make(store, /* array */[]))))]))));
            }),
          /* initialState */component[/* initialState */10],
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */component[/* reducer */12],
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.str = str;
exports.component = component;
exports.make = make;
/* component Not a pure module */
