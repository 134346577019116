// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var UserData = require("../Util/UserData");
var ReasonReact = require("reason-react/src/ReasonReact.js");

function str(prim) {
  return prim;
}

function updateEmail($$event) {
  return /* EmailUpdate */Block.__(1, [$$event.target.value]);
}

function submit($$event) {
  $$event.preventDefault();
  return /* Fetch */0;
}

var component = ReasonReact.reducerComponent("ForgotPassword");

function make(store, _children) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (self) {
              var tmp;
              if (self[/* state */1][/* fetch */1] !== /* Success */2) {
                var match = self[/* state */1][/* fetch */1];
                tmp = React.createElement("div", undefined, React.createElement("fieldset", undefined, "Please provide us with the email \n                      address associated with your KNL \n                      Portal account. You will receive a \n                      link for resetting your password.", React.createElement("br", undefined), React.createElement("br", undefined)), React.createElement("fieldset", undefined, React.createElement("legend", undefined, "Email"), React.createElement("input", {
                              className: "login-input",
                              name: "email",
                              type: "text",
                              value: self[/* state */1][/* email */0],
                              onChange: (function (e) {
                                  return Curry._1(self[/* send */3], /* EmailUpdate */Block.__(1, [e.target.value]));
                                })
                            })), typeof match === "number" ? React.createElement("div", undefined) : React.createElement("div", {
                            className: "login-error blink_me"
                          }, match[0]), React.createElement("button", {
                          className: "log-in-button primary",
                          name: "submit"
                        }, "Submit"));
              } else {
                tmp = React.createElement("div", {
                      name: "SubmitDone"
                    }, "A link for resetting the password\n                  has been provided to the specified \n                  email address.");
              }
              return React.createElement("div", {
                          className: "login row",
                          name: "ForgotPassword"
                        }, React.createElement("div", {
                              className: "col-lg-4 col-md-2 col-sm-1"
                            }), React.createElement("div", {
                              className: "login-content col-lg-4 col-md-8 col-sm-10"
                            }, React.createElement("form", {
                                  onSubmit: (function (e) {
                                      return Curry._1(self[/* send */3], (e.preventDefault(), /* Fetch */0));
                                    })
                                }, tmp)));
            }),
          /* initialState */(function (param) {
              return /* record */[
                      /* email */"",
                      /* fetch : NotAsked */0
                    ];
            }),
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */(function (action, state) {
              if (typeof action === "number") {
                if (action === /* Fetch */0) {
                  return /* UpdateWithSideEffects */Block.__(2, [
                            /* record */[
                              /* email */state[/* email */0],
                              /* fetch : Loading */1
                            ],
                            (function (self) {
                                UserData.resetPasswordUser(state[/* email */0]).then((function (result) {
                                        if (result.tag) {
                                          return Promise.resolve(Curry._1(self[/* send */3], /* FetchFailed */Block.__(0, [result[0]])));
                                        } else {
                                          return Promise.resolve(Curry._1(self[/* send */3], /* FetchSuccess */1));
                                        }
                                      }));
                                return /* () */0;
                              })
                          ]);
                } else {
                  return /* Update */Block.__(0, [/* record */[
                              /* email */state[/* email */0],
                              /* fetch : Success */2
                            ]]);
                }
              } else if (action.tag) {
                return /* Update */Block.__(0, [/* record */[
                            /* email */action[0],
                            /* fetch */state[/* fetch */1]
                          ]]);
              } else {
                var match = action[0];
                if (match) {
                  return /* Update */Block.__(0, [/* record */[
                              /* email */state[/* email */0],
                              /* fetch : Failure */[match[0]]
                            ]]);
                } else {
                  return /* Update */Block.__(0, [/* record */[
                              /* email */state[/* email */0],
                              /* fetch : Failure */["Unauthorized"]
                            ]]);
                }
              }
            }),
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.str = str;
exports.updateEmail = updateEmail;
exports.submit = submit;
exports.component = component;
exports.make = make;
/* component Not a pure module */
