// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var MapVesselSearchItemSelected = require("./MapVesselSearchItemSelected.bs.js");

var component = ReasonReact.statelessComponent("MapVesselSearchItem");

function str(prim) {
  return prim;
}

function refCallback(selected, theRef, param) {
  if (selected) {
    if (theRef == null) {
      
    } else {
      theRef.parentNode["scrollTop"] = theRef.offsetTop - 165 | 0;
    }
  }
  return /* () */0;
}

function make(name, imo, timestamp, dateTime, mail, track, file, connectionType, heading, speed, latitude, longitude, selected, onClick, _children) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (self) {
              var cls = selected ? "map-vessel-search-vessel selected" : "map-vessel-search-vessel";
              if (track) {
                React.createElement("i", {
                      className: "fa fa-map-o"
                    });
              }
              if (mail) {
                React.createElement("i", {
                      className: "fa fa-envelope-o"
                    });
              }
              if (file) {
                React.createElement("i", {
                      className: "fa fa-floppy-o"
                    });
              }
              return React.createElement("div", {
                          ref: Curry._1(self[/* handle */0], (function (param, param$1) {
                                  return refCallback(selected, param, param$1);
                                })),
                          className: cls,
                          onClick: onClick
                        }, React.createElement("div", undefined, React.createElement("strong", undefined, name), React.createElement("div", {
                                  className: "right"
                                }, "IMO: " + imo), React.createElement("div", {
                                  className: "clearboth"
                                })), selected ? ReasonReact.element(undefined, undefined, MapVesselSearchItemSelected.make(name, imo, timestamp, dateTime, false, false, false, connectionType, heading, speed, latitude, longitude, /* array */[])) : React.createElement("div", undefined));
            }),
          /* initialState */component[/* initialState */10],
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */component[/* reducer */12],
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

var jsComponent = ReasonReact.wrapReasonForJs(component, (function (jsProps) {
        return make(jsProps.name, jsProps.imo, jsProps.timestamp, jsProps.dateTime, jsProps.mail, jsProps.track, jsProps.file, jsProps.connectionType, jsProps.heading, jsProps.speed, jsProps.latitude, jsProps.longitude, jsProps.selected, jsProps.onClick, /* array */[]);
      }));

exports.component = component;
exports.str = str;
exports.refCallback = refCallback;
exports.make = make;
exports.jsComponent = jsComponent;
/* component Not a pure module */
