export default function(speed) {
    const knots = speed * 1.94384449;
    const scaled = (knots - 2.0) / (20.0 - 2.0);
    if (scaled > 1.0) {
        return 1.0;
    } else if (scaled < 0.0) {
        return 0.0;
    } else {
        return scaled;
    }
};