// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var ReactDOMRe = require("reason-react/src/ReactDOMRe.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");

var component = ReasonReact.statelessComponent("KNLCard");

function str(prim) {
  return prim;
}

function make($staropt$star, header, children) {
  var style = $staropt$star !== undefined ? Caml_option.valFromOption($staropt$star) : { };
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (_self) {
              return React.createElement("div", {
                          className: "card fluid",
                          style: style
                        }, React.createElement("h2", {
                              className: "knl-card-header section double-padded",
                              style: { }
                            }, header), ReactDOMRe.createElementVariadic("div", undefined, children));
            }),
          /* initialState */component[/* initialState */10],
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */component[/* reducer */12],
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

var jsComponent = ReasonReact.wrapReasonForJs(component, (function (jsProps) {
        return make(Caml_option.some(jsProps.style), jsProps.header, jsProps.children);
      }));

exports.component = component;
exports.str = str;
exports.make = make;
exports.jsComponent = jsComponent;
/* component Not a pure module */
