import * as L from "leaflet";
import { MapControl, withLeaflet } from "react-leaflet";
import * as MapIcon from "../../JSComponents/Icons";
import { latToDegree, lonToDegree } from '../../Components/MapVesselSearch/MapVesselSearchItemSelected.bs';
import { distancePerZoomLevel, shouldDraw } from "./GLArrows";
import { sortBy } from 'lodash';

const webglIcon = L.divIcon({
    iconAnchor: [24, 24],
    className: 'webgl-icon'
});

const makePopup = (rtposData) => {
    return (
    `<div style="width: 16em">
        <div style="font-size: .7em; width: 8.5em; float: left;">Received:&nbsp;</div><div style="font-size: .7em;">${rtposData.time.split(".")[0]}&nbsp;UTC</div>
        <div style="font-size: .7em; width: 8.5em; float: left;">Conn.&nbsp;type:&nbsp;</div><div style="font-size: .7em;">${rtposData.cell_conn ? "Cellular" : "HF"}</div>
        <div style="font-size: .7em; width: 8.5em; float: left;">Location:&nbsp;</div><div style="font-size: .7em;">${latToDegree(rtposData.latitude)}</div>
        <div style="font-size: .7em; width: 8.5em; float: left;">&nbsp;</div><div style="font-size: .7em;">${lonToDegree(rtposData.longitude)}</div>
        <div style="font-size: .7em; width: 8.5em; float: left;">Course&nbsp;(COG):&nbsp;</div><div style="font-size: .7em;">${parseInt(rtposData.heading)}°</div>
        <div style="font-size: .7em; width: 8.5em; float: left;">Speed (SOG):&nbsp;</div><div style="font-size: .7em;">${(rtposData.speed * 1.94384449).toFixed(2)}kn</div>
    </div>`
    );
}

const makeMarkers = points => points.map((point, index) => {
    const zIndex = index + 2;
    const heading = point.heading === undefined ? (point.bearing === undefined ? 0 : point.bearing) : point.heading;
    const speed = point.speed === undefined ? 0 : point.speed;
    const marker = L.marker([point.latitude, point.longitude], {icon: MapIcon.iconGen(speed, heading), zIndexOffset: zIndex});
    marker.bindPopup(makePopup(point));
    return marker;
});

class GLArrows extends MapControl {

    constructor(props) {
        super(props);
        this.markers = [];
        this.points = [];
    }

    clearMarkers() {
        for (const marker of this.markers) {
            marker.remove();
        }
    }

    drawMarkers(map) {
        const zoomLevel = map.getZoom();
        const distanceThreshold = distancePerZoomLevel(zoomLevel);
        let prevPoint = undefined;
        const mapBounds = map.getBounds();
        for (const [index, point] of this.points.entries()) {
            const marker = this.markers[index];
            if (shouldDraw(prevPoint, point, distanceThreshold)) {
                prevPoint = point;
                if (mapBounds.contains([point.latitude, point.longitude])) {
                    marker.addTo(map);
                } else {
                    marker.remove();
                }
            } else if (marker) {
                marker.remove();
            }
        }
        const marker = this.markers[(this.markers.length - 1)];
        if (marker) {
            marker.addTo(map);
        }
    }

    updateLeafletElement(fromProps, toProps) {
        const { points, leaflet } = toProps;
        this.clearMarkers();
        this.points = sortBy(points, [item => Date.parse(item.time.replace(" ", "T"))]);
        this.markers = makeMarkers(this.points);
        this.drawMarkers(leaflet.map);
    }

    createLeafletElement(props) {
        const { points, leaflet } = props;
        this.points = sortBy(points, [item => Date.parse(item.time.replace(" ", "T"))]);
        this.markers = makeMarkers(this.points);
        leaflet.map.on("zoomend", () => this.drawMarkers(leaflet.map));
        leaflet.map.on("moveend", () => this.drawMarkers(leaflet.map));
        return { 
            addTo: map => this.drawMarkers(map),
            remove: () => this.clearMarkers(), 
        };
    }
}

export default withLeaflet(GLArrows);