// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var ReasonReact = require("reason-react/src/ReasonReact.js");
var MapPageJs = require("./MapPage");

var mapComponent = MapPageJs.default;

function make(logoutCallback, $staropt$star, children) {
  var user = $staropt$star !== undefined ? $staropt$star : "?";
  return ReasonReact.wrapJsForReason(mapComponent, {
              user: user,
              logoutCallback: logoutCallback
            }, children);
}

exports.mapComponent = mapComponent;
exports.make = make;
/* mapComponent Not a pure module */
