// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Menu = require("./Components/Menu");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Fleet = require("./Pages/Fleet");
var Login = require("./Pages/Login");
var Users = require("./Pages/Users");
var React = require("react");
var Header = require("./Layout/Header");
var Profile = require("./Pages/Profile");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var GlobalStore = require("./GlobalStore");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var MapPageReason = require("./JSPages/MapPageReason");
var ResetPassword = require("./Pages/ResetPassword");
var ForgotPassword = require("./Pages/ForgotPassword");
var ActivateAccount = require("./Pages/ActivateAccount");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");
var CustomerManagement = require("./Pages/CustomerManagement");

function str(prim) {
  return prim;
}

function arr(prim) {
  return prim;
}

function toPage(url) {
  var match = url[/* path */0];
  if (match) {
    switch (match[0]) {
      case "activate" :
          if (match[1]) {
            return /* Dashboard */2;
          } else {
            return /* ActivateAccount */7;
          }
      case "customermanagement" :
          if (match[1]) {
            return /* Dashboard */2;
          } else {
            return /* CustomerManagement */9;
          }
      case "fleet" :
          if (match[1]) {
            return /* Dashboard */2;
          } else {
            return /* Fleet */6;
          }
      case "forgotpassword" :
          return /* ForgotPassword */1;
      case "login" :
          return /* Login */0;
      case "map" :
          if (match[1]) {
            return /* Dashboard */2;
          } else {
            return /* Map */5;
          }
      case "password_reset" :
          return /* ResetPassword */8;
      case "profile" :
          if (match[1]) {
            return /* Dashboard */2;
          } else {
            return /* Profile */4;
          }
      case "user" :
          var match$1 = match[1];
          if (match$1 && !match$1[1]) {
            return /* User */[Caml_format.caml_int_of_string(match$1[0])];
          } else {
            return /* Dashboard */2;
          }
      case "users" :
          if (match[1]) {
            return /* Dashboard */2;
          } else {
            return /* Users */3;
          }
      default:
        return /* Dashboard */2;
    }
  } else {
    return /* Dashboard */2;
  }
}

function toUrl(page) {
  if (typeof page === "number") {
    switch (page) {
      case /* Login */0 :
          return "login";
      case /* ForgotPassword */1 :
          return "forgotpassword";
      case /* Dashboard */2 :
          return "dashboard";
      case /* Users */3 :
          return "users";
      case /* Profile */4 :
          return "profile";
      case /* Map */5 :
          return "map";
      case /* Fleet */6 :
          return "fleet";
      case /* ActivateAccount */7 :
          return "activate";
      case /* ResetPassword */8 :
          return "password_reset";
      case /* CustomerManagement */9 :
          return "customermanagement";
      
    }
  } else {
    return "user/" + String(page[0]);
  }
}

var Mapper = {
  toPage: toPage,
  toUrl: toUrl
};

var component = ReasonReact.reducerComponent("App");

function make(_children) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */(function (self) {
              var watcherID = ReasonReactRouter.watchUrl((function (url) {
                      return Curry._1(self[/* send */3], /* UpdatePage */[toPage(url)]);
                    }));
              return Curry._1(self[/* onUnmount */4], (function (param) {
                            return ReasonReactRouter.unwatchUrl(watcherID);
                          }));
            }),
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (self) {
              return ReasonReact.element(undefined, undefined, GlobalStore.make((function (store) {
                                var tmp;
                                if (self[/* state */1][/* route */0] === /* ResetPassword */8) {
                                  tmp = ReasonReact.element(undefined, undefined, ResetPassword.make(/* array */[]));
                                } else if (self[/* state */1][/* route */0] === /* ActivateAccount */7) {
                                  tmp = ReasonReact.element(undefined, undefined, ActivateAccount.make(/* array */[]));
                                } else if (self[/* state */1][/* route */0] === /* ForgotPassword */1) {
                                  tmp = ReasonReact.element(undefined, undefined, ForgotPassword.make(store, /* array */[]));
                                } else if (self[/* state */1][/* route */0] === /* Login */0 || !store[/* state */0][/* loggedIn */0]) {
                                  tmp = ReasonReact.element(undefined, undefined, Login.make(store, /* array */[]));
                                } else {
                                  var match = self[/* state */1][/* route */0];
                                  var tmp$1;
                                  var exit = 0;
                                  if (typeof match === "number") {
                                    switch (match) {
                                      case /* Users */3 :
                                          tmp$1 = ReasonReact.element(undefined, undefined, Users.make(store, /* array */[]));
                                          break;
                                      case /* Profile */4 :
                                          tmp$1 = ReasonReact.element(undefined, undefined, Profile.make(store, /* array */[]));
                                          break;
                                      case /* Map */5 :
                                          tmp$1 = ReasonReact.element(undefined, undefined, MapPageReason.make(store[/* logout */2], undefined, /* array */[]));
                                          break;
                                      case /* Fleet */6 :
                                          tmp$1 = ReasonReact.element(undefined, undefined, Fleet.make(store, /* array */[]));
                                          break;
                                      case /* Login */0 :
                                      case /* ForgotPassword */1 :
                                      case /* Dashboard */2 :
                                      case /* ActivateAccount */7 :
                                      case /* ResetPassword */8 :
                                          exit = 1;
                                          break;
                                      case /* CustomerManagement */9 :
                                          tmp$1 = ReasonReact.element(undefined, undefined, CustomerManagement.make(store, /* array */[]));
                                          break;
                                      
                                    }
                                  } else {
                                    exit = 1;
                                  }
                                  if (exit === 1) {
                                    tmp$1 = ReasonReact.element(undefined, undefined, MapPageReason.make(store[/* logout */2], undefined, /* array */[]));
                                  }
                                  tmp = React.createElement("div", {
                                        className: "main-row row"
                                      }, React.createElement("div", {
                                            className: "nav-container col-md-3 col-lg-2"
                                          }, ReasonReact.element(undefined, undefined, Menu.make(store, self[/* state */1][/* route */0], /* array */[]))), React.createElement("main", {
                                            className: "col-sm-12 col-md-9 col-lg-10"
                                          }, React.createElement("div", {
                                                className: "main-fluid fluid"
                                              }, tmp$1)));
                                }
                                return React.createElement("div", {
                                            id: "app"
                                          }, ReasonReact.element(undefined, undefined, Header.make(store, self[/* state */1][/* route */0] === /* ActivateAccount */7 || self[/* state */1][/* route */0] === /* Login */0 || self[/* state */1][/* route */0] === /* ForgotPassword */1 || self[/* state */1][/* route */0] === /* ResetPassword */8 || !store[/* state */0][/* loggedIn */0], /* array */[])), tmp, React.createElement("div", {
                                                id: "footer"
                                              }, React.createElement("a", {
                                                    href: "https://knlnetworks.com/terms-and-conditions/",
                                                    target: "_blank"
                                                  }, "Terms and Conditions"), " | ", React.createElement("a", {
                                                    href: "https://knlnetworks.com/privacy-policy/",
                                                    target: "_blank"
                                                  }, "Privacy Policy"), " | ", React.createElement("a", {
                                                    href: "https://knlnetworks.com",
                                                    target: "_blank"
                                                  }, "www.knlnetworks.com"), " | ", React.createElement("a", {
                                                    href: "mailto:support@knlnetworks.com"
                                                  }, "support@knlnetworks.com")));
                              }), /* array */[]));
            }),
          /* initialState */(function (param) {
              return /* record */[/* route */toPage(ReasonReactRouter.dangerouslyGetInitialUrl(/* () */0))];
            }),
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */(function (action, state) {
              return /* UpdateWithSideEffects */Block.__(2, [
                        /* record */[/* route */action[0]],
                        (function (_self) {
                            return /* () */0;
                          })
                      ]);
            }),
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

var el = ReasonReact.element;

exports.str = str;
exports.el = el;
exports.arr = arr;
exports.Mapper = Mapper;
exports.component = component;
exports.make = make;
/* component Not a pure module */
