// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");

var component = ReasonReact.statelessComponent("DataUsage");

function str(prim) {
  return prim;
}

function toMegaBytes(b) {
  return b / 1024.0 / 1024.0;
}

function toMegaBytesI(b) {
  return (b / 1024 | 0) / 1024 | 0;
}

function make(usage, usedBytes, limitBytes, _children) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (_self) {
              var used = usedBytes / limitBytes * 100.0;
              var limit = usedBytes < limitBytes ? 100.0 : limitBytes / usedBytes * 100.0;
              var unit = toMegaBytes(limitBytes / 4.0);
              var step1 = String(unit | 0);
              var s = unit | 0;
              var step2 = String((s << 1));
              var s$1 = unit | 0;
              var step3 = String(Caml_int32.imul(s$1, 3));
              var s$2 = unit | 0;
              var step4 = String((s$2 << 2));
              var color = usage ? "#92cd89" : "#32a7b8";
              var usageLabelPosition = used < 100.0 ? "calc(100% - 7.5em)" : "calc(100% - 10em)";
              return React.createElement("div", {
                          className: "data-usage"
                        }, React.createElement("strong", undefined, usage ? "Shore (Cellular)" : "Sea (HF)"), React.createElement("div", {
                              className: "usage-chart",
                              style: {
                                width: Pervasives.string_of_float(limit) + "0%"
                              }
                            }, React.createElement("div", {
                                  className: "used",
                                  style: {
                                    backgroundColor: "#DEB030",
                                    width: Pervasives.string_of_float(used) + "0%"
                                  }
                                }, React.createElement("span", {
                                      className: "usage-label",
                                      style: {
                                        left: usageLabelPosition
                                      }
                                    }, String(toMegaBytesI(usedBytes)) + " MB"), React.createElement("div", {
                                      className: "used-within-limit",
                                      style: {
                                        backgroundColor: color,
                                        width: Pervasives.string_of_float(limit) + "0%"
                                      }
                                    })), React.createElement("div", {
                                  className: "markers"
                                }, React.createElement("div", {
                                      className: "marker"
                                    }, React.createElement("span", {
                                          className: "marker-label"
                                        }, step1)), React.createElement("div", {
                                      className: "marker"
                                    }, React.createElement("span", {
                                          className: "marker-label"
                                        }, step2)), React.createElement("div", {
                                      className: "marker"
                                    }, React.createElement("span", {
                                          className: "marker-label"
                                        }, step3)), React.createElement("span", {
                                      className: "marker-label"
                                    }, step4))));
            }),
          /* initialState */component[/* initialState */10],
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */component[/* reducer */12],
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.component = component;
exports.str = str;
exports.toMegaBytes = toMegaBytes;
exports.toMegaBytesI = toMegaBytesI;
exports.make = make;
/* component Not a pure module */
