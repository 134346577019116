// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var React = require("react");
var MenuLink = require("./MenuLink");
var ReasonReact = require("reason-react/src/ReasonReact.js");

var component = ReasonReact.statelessComponent("Menu");

function str(prim) {
  return prim;
}

function anyUser(user) {
  if (typeof user === "number") {
    return false;
  } else {
    return true;
  }
}

function make(store, route, _children) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (_self) {
              return $$Array.of_list(/* :: */[
                          React.createElement("input", {
                                key: "1",
                                className: "drawer",
                                id: "doc-drawer-checkbox",
                                type: "checkbox",
                                value: "on"
                              }),
                          /* :: */[
                            React.createElement("nav", {
                                  key: "2",
                                  id: "nav-drawer"
                                }, ReasonReact.element(undefined, undefined, MenuLink.make("/map", "MAP", route === /* Map */5, "fa fa-map-o", /* array */[])), ReasonReact.element(undefined, undefined, MenuLink.make("/users", "USER MANAGEMENT", route === /* Users */3, "fa fa-user", /* array */[])), store[/* state */0][/* knlAdmin */6] ? ReasonReact.element(undefined, undefined, MenuLink.make("/customermanagement", "CUSTOMERS", route === /* CustomerManagement */9, "fa fa-user-plus", /* array */[])) : React.createElement("div", undefined)),
                            /* [] */0
                          ]
                        ]);
            }),
          /* initialState */component[/* initialState */10],
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */component[/* reducer */12],
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.component = component;
exports.str = str;
exports.anyUser = anyUser;
exports.make = make;
/* component Not a pure module */
