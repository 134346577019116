// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");

function getFromStorage(valueKey, valueDefault) {
  var match = localStorage.getItem(valueKey);
  if (match !== null) {
    return /* Ok */Block.__(0, [match]);
  } else {
    return /* Error */Block.__(1, [valueDefault]);
  }
}

function getIntFromStorage(a, b) {
  var match = getFromStorage(a, b);
  if (match.tag) {
    return match[0];
  } else {
    try {
      return Caml_format.caml_int_of_string(match[0]);
    }
    catch (exn){
      return b;
    }
  }
}

function getStringFromStorage(a, b) {
  return getFromStorage(a, b)[0];
}

function getBoolFromStorage(a, b) {
  var match = getFromStorage(a, b);
  if (match.tag || match[0] !== "true") {
    return false;
  } else {
    return true;
  }
}

function setToStorage(valueKey, value) {
  localStorage.setItem(valueKey, value);
  return /* () */0;
}

function setIntToStorage(valueKey, value) {
  return setToStorage(valueKey, String(value));
}

function setBoolToStorage(valueKey, value) {
  if (value) {
    return setToStorage(valueKey, "true");
  } else {
    return setToStorage(valueKey, "false");
  }
}

var setStringToStorage = setToStorage;

exports.getFromStorage = getFromStorage;
exports.getIntFromStorage = getIntFromStorage;
exports.getStringFromStorage = getStringFromStorage;
exports.getBoolFromStorage = getBoolFromStorage;
exports.setToStorage = setToStorage;
exports.setIntToStorage = setIntToStorage;
exports.setStringToStorage = setStringToStorage;
exports.setBoolToStorage = setBoolToStorage;
/* No side effect */
