// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");

var component = ReasonReact.statelessComponent("Greeting");

function str(prim) {
  return prim;
}

function onClick($$event, href) {
  $$event.preventDefault();
  return ReasonReactRouter.push(href);
}

function make(href, name, active, $staropt$star, _children) {
  var faIcon = $staropt$star !== undefined ? Caml_option.valFromOption($staropt$star) : undefined;
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (_self) {
              var classes = active ? "selected" : "";
              var icon = faIcon !== undefined ? React.createElement("i", {
                      className: faIcon
                    }) : React.createElement("i", {
                      className: "fa"
                    });
              return React.createElement("a", {
                          className: classes,
                          href: href,
                          onClick: (function ($$event) {
                              return onClick($$event, href);
                            })
                        }, icon, name);
            }),
          /* initialState */component[/* initialState */10],
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */component[/* reducer */12],
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.component = component;
exports.str = str;
exports.onClick = onClick;
exports.make = make;
/* component Not a pure module */
