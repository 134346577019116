// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$Storage = require("../Util/Storage");
var UserData = require("../Util/UserData");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");

function str(prim) {
  return prim;
}

function updateEmail($$event) {
  return /* EmailUpdate */Block.__(1, [$$event.target.value]);
}

function updatePassword($$event) {
  return /* PasswordUpdate */Block.__(2, [$$event.target.value]);
}

function updateRememberMe($$event) {
  return /* RememberMeUpdate */Block.__(3, [$$event.target.checked]);
}

function updateAcceptCookies($$event) {
  return /* AcceptCookiesUpdate */Block.__(4, [$$event.target.checked]);
}

function submitCredentials($$event) {
  $$event.preventDefault();
  return /* Authentication */Block.__(0, [/* Load */0]);
}

var component = ReasonReact.reducerComponent("Login");

function make(store, _children) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (self) {
              var match = self[/* state */1][/* authentication */2];
              var tmp;
              tmp = typeof match === "number" || match.tag ? React.createElement("div", undefined) : React.createElement("div", {
                      className: "login-error blink_me"
                    }, match[0]);
              var match$1 = !self[/* state */1][/* acceptCookies */4];
              return React.createElement("div", {
                          className: "login row",
                          name: "Login"
                        }, React.createElement("div", {
                              className: "col-lg-4 col-md-2 col-sm-1"
                            }), React.createElement("div", {
                              className: "login-content col-lg-4 col-md-8 col-sm-10"
                            }, React.createElement("form", {
                                  onSubmit: (function (e) {
                                      return Curry._1(self[/* send */3], (e.preventDefault(), /* Authentication */Block.__(0, [/* Load */0])));
                                    })
                                }, React.createElement("fieldset", undefined, React.createElement("legend", undefined, "Email"), React.createElement("input", {
                                          className: "login-input",
                                          name: "email",
                                          type: "text",
                                          value: self[/* state */1][/* email */0],
                                          onChange: (function (e) {
                                              return Curry._1(self[/* send */3], /* EmailUpdate */Block.__(1, [e.target.value]));
                                            })
                                        })), React.createElement("fieldset", undefined, React.createElement("legend", undefined, "Password", React.createElement("span", {
                                              className: "forgotten-password",
                                              onClick: (function (param) {
                                                  return Curry._1(self[/* send */3], /* ForgotPassword */0);
                                                })
                                            }, "Forgot password?")), React.createElement("input", {
                                          className: "login-input",
                                          name: "password",
                                          type: "password",
                                          value: self[/* state */1][/* password */1],
                                          onChange: (function (e) {
                                              return Curry._1(self[/* send */3], /* PasswordUpdate */Block.__(2, [e.target.value]));
                                            })
                                        })), React.createElement("fieldset", {
                                      className: "remember-me"
                                    }, React.createElement("label", {
                                          htmlFor: "remember-me"
                                        }, "Remember me"), React.createElement("div", {
                                          className: "remember-me-checkbox-container"
                                        }, React.createElement("input", {
                                              checked: self[/* state */1][/* rememberMe */3],
                                              type: "checkbox",
                                              onChange: (function (e) {
                                                  return Curry._1(self[/* send */3], /* RememberMeUpdate */Block.__(3, [e.target.checked]));
                                                })
                                            }), React.createElement("div", {
                                              "aria-label": "When checked, you will stay logged in for seven days without needing to re-enter your password. If unchecked, you will be prompted for your password in one day.",
                                              className: "tooltip right remember-me-helper"
                                            }, "?"))), React.createElement("fieldset", {
                                      className: "remember-me"
                                    }, React.createElement("label", {
                                          htmlFor: "remember-me"
                                        }, "Accept our use of cookies. To learn more, click ", React.createElement("a", {
                                              href: "https://knlnetworks.com/privacy-policy/"
                                            }, "here.")), React.createElement("div", {
                                          className: "remember-me-checkbox-container"
                                        }, React.createElement("input", {
                                              checked: self[/* state */1][/* acceptCookies */4],
                                              type: "checkbox",
                                              onChange: (function (e) {
                                                  return Curry._1(self[/* send */3], /* AcceptCookiesUpdate */Block.__(4, [e.target.checked]));
                                                })
                                            }))), tmp, React.createElement("div", {
                                      "aria-label": "You must accept our use of cookies before you continue to use our site.",
                                      className: match$1 ? "tooltip log-in-button" : "log-in-button"
                                    }, React.createElement("button", {
                                          className: "primary",
                                          disabled: !self[/* state */1][/* acceptCookies */4],
                                          name: "login"
                                        }, "Log in")))));
            }),
          /* initialState */(function (param) {
              return /* record */[
                      /* email */"",
                      /* password */"",
                      /* authentication : Idle */0,
                      /* rememberMe */false,
                      /* acceptCookies */$$Storage.getBoolFromStorage("Login.acceptCookies", false)
                    ];
            }),
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */(function (action, state) {
              if (typeof action === "number") {
                return /* SideEffects */Block.__(1, [(function (param) {
                              return ReasonReactRouter.push("forgotpassword");
                            })]);
              } else {
                switch (action.tag | 0) {
                  case /* Authentication */0 :
                      var match = action[0];
                      if (typeof match === "number") {
                        return /* UpdateWithSideEffects */Block.__(2, [
                                  /* record */[
                                    /* email */state[/* email */0],
                                    /* password */state[/* password */1],
                                    /* authentication : Loading */1,
                                    /* rememberMe */state[/* rememberMe */3],
                                    /* acceptCookies */state[/* acceptCookies */4]
                                  ],
                                  (function (self) {
                                      UserData.login(self[/* state */1][/* email */0], self[/* state */1][/* password */1], self[/* state */1][/* rememberMe */3]).then((function (result) {
                                              return Promise.resolve(Curry._1(self[/* send */3], /* Authentication */Block.__(0, [result])));
                                            }));
                                      return /* () */0;
                                    })
                                ]);
                      } else if (match.tag) {
                        var match$1 = match[0];
                        if (match$1) {
                          return /* Update */Block.__(0, [/* record */[
                                      /* email */state[/* email */0],
                                      /* password */state[/* password */1],
                                      /* authentication : Failed */Block.__(0, [match$1[0]]),
                                      /* rememberMe */state[/* rememberMe */3],
                                      /* acceptCookies */state[/* acceptCookies */4]
                                    ]]);
                        } else {
                          return /* Update */Block.__(0, [/* record */[
                                      /* email */state[/* email */0],
                                      /* password */state[/* password */1],
                                      /* authentication : Failed */Block.__(0, ["Wrong email or password"]),
                                      /* rememberMe */state[/* rememberMe */3],
                                      /* acceptCookies */state[/* acceptCookies */4]
                                    ]]);
                        }
                      } else {
                        var match$2 = match[0];
                        var userStatus = match$2[1];
                        var authStatus = match$2[0];
                        return /* UpdateWithSideEffects */Block.__(2, [
                                  /* record */[
                                    /* email */state[/* email */0],
                                    /* password */state[/* password */1],
                                    /* authentication : Done */Block.__(1, [/* tuple */[
                                          authStatus,
                                          userStatus
                                        ]]),
                                    /* rememberMe */state[/* rememberMe */3],
                                    /* acceptCookies */state[/* acceptCookies */4]
                                  ],
                                  (function (self) {
                                      return Curry._1(store[/* dispatch */1], /* LoggedIn */Block.__(0, [
                                                    authStatus,
                                                    userStatus
                                                  ]));
                                    })
                                ]);
                      }
                  case /* EmailUpdate */1 :
                      return /* Update */Block.__(0, [/* record */[
                                  /* email */action[0],
                                  /* password */state[/* password */1],
                                  /* authentication */state[/* authentication */2],
                                  /* rememberMe */state[/* rememberMe */3],
                                  /* acceptCookies */state[/* acceptCookies */4]
                                ]]);
                  case /* PasswordUpdate */2 :
                      return /* Update */Block.__(0, [/* record */[
                                  /* email */state[/* email */0],
                                  /* password */action[0],
                                  /* authentication */state[/* authentication */2],
                                  /* rememberMe */state[/* rememberMe */3],
                                  /* acceptCookies */state[/* acceptCookies */4]
                                ]]);
                  case /* RememberMeUpdate */3 :
                      return /* Update */Block.__(0, [/* record */[
                                  /* email */state[/* email */0],
                                  /* password */state[/* password */1],
                                  /* authentication */state[/* authentication */2],
                                  /* rememberMe */action[0],
                                  /* acceptCookies */state[/* acceptCookies */4]
                                ]]);
                  case /* AcceptCookiesUpdate */4 :
                      var value = action[0];
                      return /* UpdateWithSideEffects */Block.__(2, [
                                /* record */[
                                  /* email */state[/* email */0],
                                  /* password */state[/* password */1],
                                  /* authentication */state[/* authentication */2],
                                  /* rememberMe */state[/* rememberMe */3],
                                  /* acceptCookies */value
                                ],
                                (function (_self) {
                                    return $$Storage.setBoolToStorage("Login.acceptCookies", value);
                                  })
                              ]);
                  
                }
              }
            }),
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.str = str;
exports.updateEmail = updateEmail;
exports.updatePassword = updatePassword;
exports.updateRememberMe = updateRememberMe;
exports.updateAcceptCookies = updateAcceptCookies;
exports.submitCredentials = submitCredentials;
exports.component = component;
exports.make = make;
/* component Not a pure module */
