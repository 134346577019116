// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var ReasonReact = require("reason-react/src/ReasonReact.js");

function str(prim) {
  return prim;
}

var component = ReasonReact.statelessComponent("User");

function make($staropt$star, name, email, username, onClick, onDelete, active, _children) {
  var selected = $staropt$star !== undefined ? $staropt$star : false;
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (_self) {
              var classes = selected ? "selected user-list-item" : "user-list-item";
              return React.createElement("div", {
                          className: classes,
                          name: name,
                          onClick: onClick
                        }, React.createElement("div", {
                              className: "user-list-item-name"
                            }, active ? name : React.createElement("i", undefined, email + ", not yet activated")), selected ? React.createElement("div", {
                                className: "user-list-item-detailed"
                              }, React.createElement("div", {
                                    className: "row"
                                  }, React.createElement("div", {
                                        className: "col-lg-3 col-md-3 col-sm-3"
                                      }, React.createElement("strong", undefined, "Name")), React.createElement("div", {
                                        className: "col-lg-9 col-md-9 col-sm-9"
                                      }, name)), React.createElement("div", {
                                    className: "row"
                                  }, React.createElement("div", {
                                        className: "col-lg-3 col-md-3 col-sm-3"
                                      }, React.createElement("strong", undefined, "Email")), React.createElement("div", {
                                        className: "col-lg-9 col-md-9 col-sm-9"
                                      }, email)), React.createElement("button", {
                                    className: "secondary",
                                    name: "delete-user",
                                    onClick: onDelete
                                  }, "DELETE USER PROFILE")) : React.createElement("div", undefined));
            }),
          /* initialState */component[/* initialState */10],
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */component[/* reducer */12],
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.str = str;
exports.component = component;
exports.make = make;
/* component Not a pure module */
